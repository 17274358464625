import React,{useState, useEffect} from 'react';
import { useNavigate, NavLink } from 'react-router-dom';
import Flowcard from "../source/TestPage/Flowcard";

const serverRoot = process.env.REACT_APP_SERVER_ROOT;


const Account = ({forminputs, setforminputs, setisLinkClick}) => {

    let UserId = localStorage.getItem('UserId');
    const navigate = useNavigate();


    const [isLoading, setisLoading] = useState(false);
    const [usergoogleaccounts, setusergoogleaccounts] = useState([]);

    const handleAccountInputs = (e) =>{
        let {value} = e.target;
        let Account = usergoogleaccounts.find(u => u.google_no == value);
        setforminputs({...forminputs, destAccount:Account});
    }

    const getusergoogleaccount = () =>{
        setisLoading(true);
        fetch(serverRoot+'getusergoogleaccounts/'+UserId,
        {
            method: "GET",
            headers: {"Content-Type": "application/json",'Accept': 'application/json'},
        }) 
        .then((response) => response.json())
        .then((responseData) =>{
            setusergoogleaccounts(responseData);
            if(!forminputs.destAccount && responseData.length > 0){
                setforminputs({...forminputs, destAccount:responseData[0]});
            }
        })
        .catch((error) => console.log(error))
        .finally(()=> setisLoading(false));
    }

    useEffect(()=>{
        getusergoogleaccount();
    },[]);

    const getToken = () =>{
        setisLinkClick(true);
        navigate("/flows/destination/google");
    }


    return ( 
        <>
            <div className="flex gap-4 m-5">
                <Flowcard forminputs={forminputs}/>

                <div className="bg-white text-black w-full h-auto px-5 py-8 drop-shadow-sm rounded-md">
                    <div>
                        <h1 className="font-[550] ">Account</h1>
                    </div>
                    <div>
                        <p className="text-gray-400 text-sm">
                            Select the Google Account you want to insert data , or add a
                            new account . <br /> you can only select one account at a time.
                        </p>
                    </div>
                    <div className="mt-4">
                        <button onClick={getToken} className="bg-light-blue-500 font-medium text-white text-sm  px-2 py-1 rounded-md tracking-wide">
                            create new account
                        </button>
                    </div>
                    <div className="capitalize mt-4 -leading-4 text-sm font-medium tracking-wider">
                        <div className="mb-4">
                            <h2 className="">Google Account</h2>
                            {
                                (isLoading) ?
                                <div className="flex bg-gray-200 w-[18rem] py-1.5 px-2">
                                    <div
                                        className="inline-block h-6 w-6 animate-spin rounded-full border-4 border-solid border-current border-r-transparent align-[-0.125em] text-gray-500 motion-reduce:animate-[spin_1.5s_linear_infinite]"
                                        role="status">
                                        <span
                                        className="!absolute !-m-px !h-px !w-px !overflow-hidden !whitespace-nowrap !border-0 !p-0 ![clip:rect(0,0,0,0)]"
                                        >Loading...</span>
                                    </div>
                                    <h1 className="text-sm ml-2 font-medium leading-normal">Loading...</h1>
                                </div>
                                :(usergoogleaccounts && usergoogleaccounts.length > 0) ?
                                    <select onChange={handleAccountInputs} defaultValue={(forminputs.destAccount)?forminputs.destAccount.google_no: "Select Account"} className="focus:outline-none bg-[#F9FAFB] border pl-2 h-9 w-[21.5rem] rounded-[4px] tracking-wider">
                                        <option value="Select Account" disabled>Select Google Account</option>
                                        {
                                             usergoogleaccounts.map((e,i)=>{
                                                return (
                                                    <option key={i} value={e.google_no}>{e.google_name + "(" +e.google_id + ")"}</option>
                                                )
                                            })
                                        }
                                    </select>
                                : <div className="flex bg-gray-200 w-[18rem] py-1.5 px-2">
                                    No Accounts Found
                                </div>
                            }
                            {/* <select onChange={handleAccountInputs} defaultValue="Select Google Account" className="focus:outline-none bg-gray-200 px-3 py-1.5 w-[18rem] rounded-sm tracking-wider">
                                <option value="Select Google Account" disabled>Select Google Account</option>
                                {   
                                    (isLoading)?
                                    <option disabled>
                                        <lord-icon
                                            src="https://cdn.lordicon.com/xjovhxra.json"
                                            trigger="loop"
                                            scale="85"
                                            colors="primary:#109173,secondary:#08a88a"
                                            className="w-[50rem] h-[50rem]">
                                        </lord-icon>
                                    </option>
                                    :
                                    usergoogleaccounts && usergoogleaccounts.length > 0 ? (
                                        usergoogleaccounts.map((e,i)=>{
                                            return (
                                                <option key={i} value={e.google_no}>{e.google_name + "(" +e.google_id + ")"}</option>
                                            )
                                        })
                                    ) : (
                                        <option disabled>No Accounts Found!</option>
                                    )
                                }
                            </select> */}
                        </div>
                        
                    </div>
                </div> 
            </div>    
        </>
     );
}
 
export default Account;