import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import toast, { Toaster } from "react-hot-toast";
import {
  validateSocialToken,
  facebookUser,
  facebookPage,
  facebookAdsPage,
  instagramPage,
} from "./API/facebook.js";
import Loader from "./Loader.js";

const Redirect = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const [fbuser, setfbuser] = useState(
    sessionStorage.data ? JSON.parse(sessionStorage.data) : {}
  );
  const category = sessionStorage.component
    ? JSON.parse(sessionStorage.component)
    : {};
  const [loading, setLoading] = useState(false);

  const code = new URLSearchParams(location.search).get("code");

  useEffect(() => {
    const jwtToken = fbuser.user.token;
    if (jwtToken && code) {
      getfbPages(jwtToken, code, category.categoryId);
    } else {
      navigate("/");
    }
  }, []);

  async function getfbPages(jwtToken, code, categoryId) {
    try {
      setLoading(true);
      const fbResponse = await validateSocialToken(jwtToken, {
        code: code,
        categoryId: categoryId,
      });
      if (fbResponse.isError) {
        return alert(fbResponse.message);
      }
      toast.success("Successfully Updated!");

      const apiResponse = await facebookUser({
        name: fbuser.user.Name,
        email: fbuser.user.UserName,
        categoryId: categoryId,
      });
      if (apiResponse.isError) {
        return alert(apiResponse.message);
      }
      sessionStorage.setItem("data", JSON.stringify(apiResponse.data));
      navigate("/connector/callback");
    } catch (error) {
      console.log("error", error);
    } finally {
      setLoading(false);
    }
  }

  return (
    <div className="w-full h-[35rem] flex justify-center items-center">
      {loading ? (
        <Loader />
      ) : (
        <div className="flex gap-5 items-center">
          <h1>Success</h1>
        </div>
      )}
      <Toaster
        position="top-right"
        reverseOrder={false}
        toastOptions={{ duration: 3000 }}
      />
    </div>
  );
};

export default Redirect;
