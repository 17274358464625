import React from "react";
import { NavLink } from "react-router-dom";
import illustration from "../../images/pngfind.png";

const Dashboard = () => {
  return (
    <>
      <div className="flex flex-col justify-center items-center mt-14">
        <div>
          <img src={illustration} alt="image" className="w-[20rem]" />
        </div>
        <div className="flex flex-col justify-center items-center">
          <h1 className="text-xl">Create your data flow</h1>
          <h4 className="text-sm">
            Connect a Source with a Destination to get your data moving
          </h4>
          <NavLink
            to="/flows"
            className="py-1 px-7 mr-2 text-sm font-medium text-white transition-all duration-200 mt-2 uppercase tracking-wider bg-cyan-500 rounded-full border border-gray-200 hover:bg-cyan-700 inline-flex items-center"
          >
            Create
          </NavLink>
        </div>
      </div>
    </>
  );
};

export default Dashboard;
