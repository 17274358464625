import React from "react";
import { NavLink, useLocation } from "react-router-dom";
import userprofile from "../images/userProfile2.png";
import logo from "../images/asset_1.png";
import { useState, useEffect } from "react";

import {
  MobileNav,
  Typography,
  IconButton,
  Avatar,
  Popover,
  PopoverHandler,
  PopoverContent,
} from "@material-tailwind/react";

const NavBar = ({ isLoggedIn, setIsLoggedIn }) => {
  const location = useLocation();

  const USER = JSON.parse(localStorage.getItem("user"));

  function Logout() {
    setIsLoggedIn(false);
    localStorage.clear();
    window.location.reload();
  }

  const [openNav, setOpenNav] = useState(false);

  useEffect(() => {
    window.addEventListener(
      "resize",
      () => window.innerWidth >= 960 && setOpenNav(false)
    );
  }, []);

  const activenav = "text-[#e53e3e]";

  const navList = (
    <>
      {isLoggedIn ? (
        <>
          <Typography
            as="li"
            variant="small"
            className="text-blue-gray-400 font-sans text-sm uppercase hover:text-blue-gray-900"
          >
            <NavLink
              to="/flows"
              className={({ isActive }) => (isActive ? activenav : "")}
            >
              Flow
            </NavLink>
          </Typography>
          <Typography
            as="li"
            variant="small"
            color="blue-gray"
            className="p-1 font-normal cursor-pointer"
          >
            <Popover placement="bottom">
              <PopoverHandler>
                <div className="flex flex-col justify-center items-center text-xs">
                  <div className="">
                    <Avatar src={userprofile} variant="circular" size="xs" />
                  </div>
                  <h1 className="text-xs font-bold text-left uppercase">
                    {USER?.Name}
                  </h1>
                </div>
              </PopoverHandler>
              <PopoverContent>
                <button
                  onClick={Logout}
                  className="flex gap-2 text-base hover:text-red-400 transition-all duration-200"
                >
                  Logout<i className="bi bi-box-arrow-right"></i>
                </button>
              </PopoverContent>
            </Popover>
          </Typography>
        </>
      ) : (
        <>
          <Typography
            as="li"
            variant="small"
            color="blue-gray"
            className="text-blue-gray-400 font-sans text-sm uppercase hover:text-blue-gray-900 mr-4"
          >
            <NavLink
              to="/login"
              className={({ isActive }) => (isActive ? activenav : "")}
            >
              Login
            </NavLink>
          </Typography>
          <Typography
            as="li"
            variant="small"
            color="blue-gray"
            className="text-blue-gray-400 font-sans text-sm uppercase hover:text-blue-gray-900 mr-10"
          >
            <NavLink
              to="/signup"
              className={({ isActive }) => (isActive ? activenav : "")}
            >
              Sign up
            </NavLink>
          </Typography>
        </>
      )}
    </>
  );

  return (
    <nav>
      <div className="bg-[#e6eeef] w-full px-4 py-2 sticky top-0 z-50">
        <div className="flex items-center justify-between text-blue-gray-900">
          <Typography
            variant="small"
            className="flex justify-center items-center ml-14 cursor-pointer font-normal"
          >
            <NavLink to="/flows">
              <img src={logo} className="w-14" />
            </NavLink>
          </Typography>
          <div className="hidden lg:block">
            <ul className="mb-4 mt-2 flex flex-col gap-2 lg:mb-0 lg:mt-0 lg:flex-row lg:items-center lg:gap-6 text-blue-gray-400 font-sans text-base uppercase">
              {location.pathname == "/connector/redirect" ||
              location.pathname == "/connector/callback" ? (
                <></>
              ) : (
                navList
              )}
            </ul>
          </div>
          <IconButton
            variant="text"
            className="ml-auto h-6 w-6 text-inherit hover:bg-transparent focus:bg-transparent active:bg-transparent lg:hidden"
            ripple={false}
            onClick={() => setOpenNav(!openNav)}
          >
            {openNav ? (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                className="h-6 w-6"
                viewBox="0 0 24 24"
                stroke="currentColor"
                strokeWidth={2}
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M6 18L18 6M6 6l12 12"
                />
              </svg>
            ) : (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-6 w-6"
                fill="none"
                stroke="currentColor"
                strokeWidth={2}
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M4 6h16M4 12h16M4 18h16"
                />
              </svg>
            )}
          </IconButton>
        </div>
        <MobileNav open={openNav}>
          {location.pathname == "/connector/redirect" ||
          location.pathname == "/connector/callback" ? (
            <></>
          ) : (
            navList
          )}
        </MobileNav>
      </div>
    </nav>
  );
};

export default NavBar;
