import React from "react";
import { Card, Button, Typography } from "@material-tailwind/react";

const FacebookLeads = () => {
  return (
    <div className="w-full">
      <Card className="max-w-[32rem] p-5 space-y-4 m-auto border">
        <Typography variant="h4">Account</Typography>
        <Typography variant="lead">
          Select the Facebook Account and page you want to retrieve leads data
          from, or{" "}
          <button className="text-blue-500 underline">add a new account</button>{" "}
          . you can only select one page at a time.
        </Typography>
        <div>
          <h2>Select Account</h2>
          <select
            name=""
            id=""
            className="focus:outline-none bg-[#F9FAFB] border pl-2 h-9 w-[21.5rem] rounded-[4px] tracking-wider"
          >
            <option value="Select Page" disabled>
              Select Page
            </option>
            <option value="1">Rohit Yadav</option>
            <option value="1">1</option>
            <option value="1">1</option>
            <option value="1">1</option>
            <option value="1">1</option>
          </select>
        </div>
        <div>
          <h2>Select Page</h2>
          <select
            name=""
            id=""
            className="focus:outline-none bg-[#F9FAFB] border pl-2 h-9 w-[21.5rem] rounded-[4px] tracking-wider"
          >
            <option value="Select Page" disabled>
              Select Page
            </option>
            <option value="SocialChamps">SocialChamps</option>
            <option value="1">1</option>
            <option value="1">1</option>
            <option value="1">1</option>
            <option value="1">1</option>
          </select>
        </div>
        <div className="flex justify-between">
          <Button size="sm" variant="outlined">
            Test
          </Button>
          <Button size="sm" variant="outlined">
            Submit
          </Button>
        </div>
      </Card>
    </div>
  );
};

export default FacebookLeads;
