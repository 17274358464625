import React, { useState } from "react";

import fbicon from "../../../../images/facebook.png";
import instaicon from "../../../../images/instagram.png";
import twittericon from "../../../../images/twitter.png";
import linkedicon from "../../../../images/linkedin.png";
import sheeticon from "../../../../images/sheets.png";

const Services = ({ onNext, setforminputs }) => {
  const myServices = [
    {
      id: 1,
      title: "facebok page",
      value: "facebook_page_insights",
      platform: "facebook",
      icon: fbicon,
      status: 1,
    },
    {
      id: 3,
      title: "facebok ads",
      value: "facebook_ads_insights",
      platform: "facebook",
      icon: fbicon,
      status: 1,
    },
    {
      id: 5,
      title: "Instagram page",
      value: "InstaPageInsights",
      platform: "instagram",
      icon: instaicon,
      status: 1,
    },
    {
      id: 2,
      title: "facebok Posts",
      value: "facebook_post_insights",
      platform: "facebook",
      icon: fbicon,
      status: 0,
    },
    {
      id: 4,
      title: "facebok leads",
      value: "facebook_leads",
      platform: "facebook",
      icon: fbicon,
      status: 0,
    },
    {
      id: 6,
      title: "instagram posts",
      value: "InstaPostInsights",
      platform: "instagram",
      icon: instaicon,
      status: 0,
    },
    {
      id: 7,
      title: "Twitter Insights",
      value: "TwitterInsights",
      platform: "twitter",
      icon: twittericon,
      status: 0,
    },
    {
      id: 8,
      title: "Twitter Ads Insights",
      value: "TwitterAds",
      platform: "twitter",
      icon: twittericon,
      status: 0,
    },
    {
      id: 9,
      title: "LinkedIn Pages",
      value: "linkedinPages",
      platform: "LinkedIn",
      icon: linkedicon,
      status: 1,
    },
  ];

  const handleInputs = (e) => {
    let selectedservice = myServices.find((u) => u.id == e.target.value);
    setforminputs({ service: selectedservice });
    onNext();
  };

  return (
    <>
      <div className="w-full min-h-screen bg-gray-100 p-10">
        <div className="w-full h-10 flex justify-center items-center ">
          <div className="relative">
            <label className="relative block">
              <span className="sr-only">Search</span>
              <span className="absolute inset-y-0 left-0 flex items-center pl-2">
                <i className="bi bi-search"></i>
              </span>
              <input
                className="placeholder:italic placeholder:text-slate-400 block bg-white w-full border border-slate-300 rounded-md py-2 pl-9 pr-12 focus:outline-none focus:border-black focus:ring-black focus:ring-1 sm:text-sm"
                placeholder="Search by Source Name"
                type="text"
                name="search"
              />
            </label>
          </div>
        </div>
        <div className="capitalize mt-10 flex flex-wrap pl-5 gap-6">
          {myServices.map((elem, i) => {
            return (
              <button
                key={i}
                onClick={() =>
                  handleInputs({
                    target: { name: "media_desc", value: elem.id },
                  })
                }
                disabled={elem.status === 1 ? false : true}
                className="h-24 w-24 bg-white hover:scale-110 hover:ring-[3px] ring-light-blue-600  rounded-[15px] drop-shadow-lg transition-all duration-300"
              >
                <div className="w-full h-[4rem] flex justify-center items-center -mt-2">
                  <img src={elem.icon} alt="icon" className="w-9" />
                </div>
                <div className="text-sm font-medium text-center px-2 -mt-2 leading-4">
                  <h2 className="capitalize">{elem.title}</h2>
                </div>
              </button>
            );
          })}
          <button className="h-24 w-24 bg-white hover:scale-110 hover:ring-[3px] ring-light-blue-600  rounded-[15px] drop-shadow-lg transition-all duration-300"></button>
          <button className="h-24 w-24 bg-white hover:scale-110 hover:ring-[3px] ring-light-blue-600  rounded-[15px] drop-shadow-lg transition-all duration-300"></button>
          <button className="h-24 w-24 bg-white hover:scale-110 hover:ring-[3px] ring-light-blue-600  rounded-[15px] drop-shadow-lg transition-all duration-300"></button>
          <button className="h-24 w-24 bg-white hover:scale-110 hover:ring-[3px] ring-light-blue-600  rounded-[15px] drop-shadow-lg transition-all duration-300"></button>
          <button className="h-24 w-24 bg-white hover:scale-110 hover:ring-[3px] ring-light-blue-600  rounded-[15px] drop-shadow-lg transition-all duration-300"></button>
          <button className="h-24 w-24 bg-white hover:scale-110 hover:ring-[3px] ring-light-blue-600  rounded-[15px] drop-shadow-lg transition-all duration-300"></button>
          <button className="h-24 w-24 bg-white hover:scale-110 hover:ring-[3px] ring-light-blue-600  rounded-[15px] drop-shadow-lg transition-all duration-300"></button>
          <button className="h-24 w-24 bg-white hover:scale-110 hover:ring-[3px] ring-light-blue-600  rounded-[15px] drop-shadow-lg transition-all duration-300"></button>
          <button className="h-24 w-24 bg-white hover:scale-110 hover:ring-[3px] ring-light-blue-600  rounded-[15px] drop-shadow-lg transition-all duration-300"></button>
          <button className="h-24 w-24 bg-white hover:scale-110 hover:ring-[3px] ring-light-blue-600  rounded-[15px] drop-shadow-lg transition-all duration-300"></button>
          <button className="h-24 w-24 bg-white hover:scale-110 hover:ring-[3px] ring-light-blue-600  rounded-[15px] drop-shadow-lg transition-all duration-300"></button>
          <button className="h-24 w-24 bg-white hover:scale-110 hover:ring-[3px] ring-light-blue-600  rounded-[15px] drop-shadow-lg transition-all duration-300"></button>
        </div>
      </div>
    </>
  );
};

export default Services;
