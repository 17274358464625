import React from "react";

const Loader = () => {
  return (
    <div className="fixed top-0 left-0 right-0 bottom-0 flex items-center justify-center z-50 bg-white/70">
      <div className="bg-white p-7 flex flex-col justify-center items-center gap-y-2 rounded-md shadow-2xl shadow-black/70 absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
        <div
          className="animate-spin inline-block w-10 h-10 border-[4px] border-current border-t-transparent text-[#4bbcf0] rounded-full"
          role="status"
          aria-label="loading"
        >
          <span className="sr-only">Loading...</span>
        </div>
        <span className="text-black text-sm">Loading...</span>
      </div>
    </div>
  );
};

export default Loader;

