import React, { useState, useEffect } from "react";
import "react-tooltip/dist/react-tooltip.css";
import { Tooltip } from "react-tooltip";
import Flowcard from "../TestPage/Flowcard";

const serverRoot = process.env.REACT_APP_SERVER_ROOT;

const Metrics = ({ forminputs, setforminputs }) => {
  const [metricsLoader, setmetricsLoader] = useState(false);
  const [insightmetricsfromdb, setinsightmetricsfromdb] = useState([]);

  const [formmetrics, setformmetrics] = useState([]);
  const [showselectedmetrics, setshowselectedmetrics] = useState([]);

  const handlemetricsInputs = (e) => {
    const { value } = e.target;
    const metrics = insightmetricsfromdb.find((u) => u.metrics_id == value);

    if (formmetrics.includes(metrics.metrics_name)) {
      const newformMetrics = formmetrics.filter(
        (item) => item !== metrics.metrics_name
      );
      setformmetrics(newformMetrics);
    } else {
      setformmetrics([...formmetrics, metrics.metrics_name]);
    }

    if (showselectedmetrics.includes(metrics.metrics_display_name)) {
      const newselectedMetrics = showselectedmetrics.filter(
        (item) => item !== metrics.metrics_display_name
      );
      setshowselectedmetrics(newselectedMetrics);
    } else {
      setshowselectedmetrics([
        ...showselectedmetrics,
        metrics.metrics_display_name,
      ]);
    }
  };

  useEffect(() => {
    setforminputs({
      ...forminputs,
      metricskeys: formmetrics,
      metricsdisplayname: showselectedmetrics,
    });
  }, [formmetrics]);

  const selecstallmetrics = () => {
    var metricsName = [];
    var metricsDisplayName = [];
    insightmetricsfromdb.map((data) => {
      metricsName = [...metricsName, data.metrics_name];
      metricsDisplayName = [...metricsDisplayName, data.metrics_display_name];
    });
    setformmetrics(metricsName);
    setshowselectedmetrics(metricsDisplayName);
  };
  const removeallmetrics = () => {
    setformmetrics([]);
    setshowselectedmetrics([]);
  };

  const callmetricstype = () => {
    switch (forminputs.service.value) {
      case "facebook_page_insights":
        findMetrics("getfbpagemetrics");
        break;
      case "facebook_post_insights":
        findMetrics("getfbpostmetrics");
        break;
      case "facebook_ads_insights":
        findMetrics("fbadsinsightmetrics");
        break;
      case "facebook_leads":
        findMetrics("fbleadsmetrics");
        break;
      case "InstaPageInsights":
        findMetrics("getinstapagemetrics");
        break;
      case "InstaPostInsights":
        findMetrics("getinstapostmetrics");
        break;
      default:
        setinsightmetricsfromdb([]);
        break;
    }
  };

  useEffect(() => {
    callmetricstype();
  }, [forminputs.service.value]);

  var findMetrics = async (fetchURL) => {
    setmetricsLoader(true);
    await fetch(serverRoot + fetchURL, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    })
      .then((response) => response.json())
      .then((responseData) => setinsightmetricsfromdb(responseData))
      .catch((error) => console.log(error))
      .finally(() => setmetricsLoader(false));
  };

  return (
    <>
      <div className="w-full min-h-screen bg-gray-100 p-10">
        <div className="flex gap-4">
          <Flowcard forminputs={forminputs} />

          <div className="relative flex bg-white h-auto w-full rounded-md px-5 py-10">
            <div className="w-[20rem]">
              <div className="flex justify-between">
                <h1 className="font-roboto text-[12px] uppercase leading-[1rem]">
                  Metrics
                </h1>
                <button
                  onClick={selecstallmetrics}
                  className="text-xs mt-1 cursor-pointer text-red-500 underline"
                >
                  Select All
                </button>
              </div>
              <div className="relative">
                <label className="relative block">
                  <span className="sr-only">Search</span>
                  <span className="absolute inset-y-0 left-0 flex items-center pl-2">
                    <i className="bi bi-search"></i>
                  </span>
                  <input
                    className="placeholder:italic placeholder:text-slate-400 block bg-gray-100 w-full border border-slate-300 rounded-t-md py-2 pl-9 pr-12 focus:outline-none sm:text-sm"
                    placeholder="Search Metrics Name"
                    type="text"
                    name="search"
                  />
                </label>
              </div>
              <div className="w-full border border-gray-100 h-[20rem] py-4 overflow-auto ">
                {metricsLoader ? (
                  <div className="bg-gray-50 flex justify-center items-center h-[18rem]">
                    <div
                      className="inline-block h-8 w-8 animate-spin rounded-full border-4 border-solid border-current border-r-transparent align-[-0.125em] text-gray-500 motion-reduce:animate-[spin_1.5s_linear_infinite]"
                      role="status"
                    >
                      <span className="!absolute !-m-px !h-px !w-px !overflow-hidden !whitespace-nowrap !border-0 !p-0 ![clip:rect(0,0,0,0)]">
                        Loading...
                      </span>
                    </div>
                  </div>
                ) : insightmetricsfromdb && insightmetricsfromdb.length > 0 ? (
                  insightmetricsfromdb.map((elem, i) => {
                    return (
                      <div
                        key={i}
                        className="w-[25rem] flex items-center rounded pl-2 h-6 text-sm"
                      >
                        <input
                          type="checkbox"
                          onChange={handlemetricsInputs}
                          value={elem.metrics_id}
                          checked={formmetrics.includes(elem.metrics_name)}
                          className="mr-2 h-4 w-4 cursor-pointer"
                          id={elem.metrics_id}
                        />
                        <label
                          htmlFor={elem.metrics_id}
                          data-tooltip-id="my-tooltip"
                          data-tooltip-content={elem.metrics_display_name.replace(
                            /_/g,
                            " "
                          )}
                          className="cursor-pointer capitalize text-gray-800 font-roboto text-[15px]"
                        >
                          {elem.metrics_display_name.replace(/_/g, " ")}
                        </label>
                      </div>
                    );
                  })
                ) : (
                  <p>No items to display</p>
                )}
              </div>
            </div>

            <div className="ml-4 w-full">
              <div className="flex justify-between">
                <h1 className="font-roboto text-[13px] uppercase leading-[1rem]">
                  Selected Metrics ({showselectedmetrics?.length}/
                  {insightmetricsfromdb?.length})
                </h1>
                <button
                  onClick={removeallmetrics}
                  className="text-xs mt-1 cursor-pointer text-red-500 underline"
                >
                  Remove All
                </button>
              </div>
              <div className="bg-gray-100 w-full h-[22rem] text-xs overflow-hidden overflow-y-auto">
                <div className="flex flex-wrap h-min">
                  {showselectedmetrics && showselectedmetrics.length > 0 ? (
                    showselectedmetrics.map((metrics, i) => {
                      return (
                        <div
                          key={i}
                          className="bg-blue-500 m-1 h-min rounded-sm text-white text-[14px] px-[4px] py-[2px]"
                        >
                          {metrics.replace(/_/g, " ")}
                        </div>
                      );
                    })
                  ) : (
                    <p className="m-1">No metrics Selected</p>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Tooltip id="my-tooltip" />
    </>
  );
};

export default Metrics;
