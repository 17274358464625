import React from 'react';
import Flowcard from './Flowcard';


const TestPage = ({forminputs, setforminputs}) => {
    return ( 
        <>  
            <div className="flex justify-center mt-4">
                {/* <div className="bg-[#173c6b] text-white w-[26rem] p-4 rounded-md drop-shadow-sm m-10">
                    <div className="w-auto flex items-center">
                        <div className="p-[6px] bg-white rounded-full w-10">
                            <img src={forminputs.service.icon} alt="icon"/>
                        </div>
                        <h2 className="tracking-wide indent-4 capitalize">{forminputs.service.title} Insights</h2>
                    </div>  
                    <div className="-leading-4 mt-6 text-sm font-extralight tracking-wider">
                        <div className="mb-4">
                            <p>Facebook Account</p>
                            <p>Rohit Yadav(18456165748519)</p>
                        </div>
                        <div className="mb-4">
                            <p>Facebook Page</p>
                            <p>SocialChamps(18456165748519)</p>
                        </div>
                        <div className="mb-4">
                            <p>Google Account</p>
                            <p>SocialChamps(18456165748519)</p>
                        </div>
                        <div className="">
                            <p>Metrics</p>
                            <div className="flex flex-wrap w-full min-h-40 text-xs">
                                {
                                    forminputs.metricsdisplayname && forminputs.metricsdisplayname.length > 0 ? (
                                        forminputs.metricsdisplayname.map((metrics,i)=>{
                                            return(
                                                <div key={i} className="bg-gray-200 m-1 h-min rounded-sm text-black px-[4px]">{metrics}</div>
                                            )
                                        })
                                    ): (
                                        <p className="m-1">No metrics Selected</p>
                                    )
                                }
                            </div>
                        </div>
                    </div>
                </div> */}
                <Flowcard forminputs={forminputs}/>
            </div>
        </>
     );
}
 
export default TestPage;