import React,{useState, useEffect} from 'react';
import {NavLink} from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
const serverRoot = process.env.REACT_APP_SERVER_ROOT;


const Forms = ({forminputs, setforminputs}) => {

    var createcronjobs = () => {
        fetch(serverRoot+"createcronjobs",
        {
            method: "POST",
            headers: {"Content-Type": "application/json",'Accept': 'application/json'},
            body: JSON.stringify({forminputs})
        }) 
        .then((response) => {
            if(response.status == 200){
                setforminputs({});
                return window.alert("submited successfully.");
            } else if(response.status === 505){
                return window.alert("google Token has been expired or revoked.");
            } else{
                return window.alert("something went wrong!");
            }
        })
        .catch((error) =>console.log(error));
    }


    return ( 
        <>
            <div className="bg-[#173c6b] text-white w-[26rem] p-4 rounded-md drop-shadow-sm">
                {
                    (forminputs.service) ? (
                        <div className="w-auto flex items-center">
                            <div className="p-[6px] bg-white rounded-full w-10">
                                <img src={forminputs.service.icon} alt="icon"/>
                            </div>
                            <h2 className="tracking-wide indent-4 capitalize">{forminputs.service.title} Insights</h2>
                        </div> 
                    ):
                    (
                        <></>
                    )
                }
                 
                <div className="-leading-4 mt-6 text-sm font-extralight tracking-wider">
                    <div className="mb-4">
                        <p>Facebook Account</p>
                        <p>{(forminputs.Account) ? forminputs.Account.fbName+"("+forminputs.Account.fbId + ")" : "-"}</p>
                    </div>
                    <div className="mb-4 capitalize">
                        <p>{(forminputs.service) ? forminputs.service.title : "Facebook Page"}</p>
                        {/* <p>{(forminputs.PageAccount)? forminputs.PageAccount.PageName ||forminputs.PageAccount.adaccount_name ||forminputs.PageAccount.InstaPageUserName + "(" + (forminputs.PageAccount.PageId || forminputs.PageAccount.adaccount_id || forminputs.PageAccount.InstaPageID) + ")" : "-"}</p> */}
                        <p>{(forminputs.PageAccount)? `${forminputs.PageAccount.PageName ||forminputs.PageAccount.adaccount_name ||forminputs.PageAccount.InstaPageUserName}(${forminputs.PageAccount.PageId || forminputs.PageAccount.adaccount_id || forminputs.PageAccount.InstaPageID})` : "-"}</p>
                    </div>
                    <div className="mb-4">
                        <p>Google Account</p>
                        <p>{(forminputs.destAccount)?forminputs.destAccount.google_name+"("+forminputs.destAccount.google_id+")" : "-"}</p>
                    </div>
                    {
                        forminputs.metricsdisplayname && forminputs.metricsdisplayname.length > 0 ? (
                            <>
                            <div className="w-full mb-4 max-h-52 py-2 shadow-inner overflow-hidden overflow-y-scroll overflow-x-hidden scrollbar-thin scrollbar-thumb-gray-700  scrollbar-thumb-rou scrollbar-track-blue-gray-50 scroll-smooth">
                                <p>Metrics</p>
                                <div className="flex flex-wrap w-full min-h-40 text-xs">
                                {

                                    forminputs.metricsdisplayname.map((metrics,i)=>{
                                        return(
                                            <div key={i} className="bg-gray-200 m-1 h-min rounded-sm text-black px-[4px]">{metrics}</div>
                                        )
                                    })
                                }
                                </div>
                            </div>
                            </>
                        ) : (
                            <></>
                        )   
                    }
                </div>
                
                <div className="flex justify-around px-5 items-center">
                    <NavLink to="/flows" className="border py-1 rounded-md px-2 tracking-wide text-sm uppercase transition-all duration-300">
                        Cancel
                    </NavLink>
                    {
                        (forminputs.Account && forminputs.destAccount && forminputs.metricskeys) ? 
                        <button onClick={createcronjobs} className="border py-1 rounded-md px-2 tracking-wide text-sm bg-white uppercase text-black transition-all duration-300">
                            Create
                        </button>
                        :
                        <></>
                    }
                </div>

            </div>
            <ToastContainer
            autoClose={2000} 
            position="bottom-right"
            theme="dark"/>
        </>
     );
}
 
export default Forms;