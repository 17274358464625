import { useState, useEffect } from "react";
import { Routes, Route, useLocation } from "react-router-dom";
import Dashboard from "./components/Dashboard/Dashboard";
import ErrorPage from "./components/ErrorPage";
import Login from "./components/LoginPage/Login";
import Signup from "./components/LoginPage/Signup";
import NavBar from "./components/Navbar";
import PrivateRoutes from "./utils/PrivateRoutes";
import Flows from "./components/copy_flows/Flows";
import Source from "./components/copy_flows/source/Source";
import Destination from "./components/copy_flows/destination/Destination";
import Forms from "./components/copy_flows/forms/Forms";

import Facebook from "./components/copy_flows/Tokens/sourceTokens/Facebook";
import Google from "./components/copy_flows/Tokens/destTokens/Google";

import Callback from "./lookerStudio/Callback.jsx";
import Redirect from "./lookerStudio/Redirect.jsx";

// *instagram login*
import InstaLogin from "./insta-login/InstaLogin.jsx";

// new facebok leads form
import FacebookLeads from "./webhook/FacebookLeads.jsx";

import { toast } from "react-toastify";
import axios from "axios";
const serverRoot = process.env.REACT_APP_SERVER_ROOT;

const Routing = ({ setIsLoggedIn }) => {
  const [isLinkClick, setisLinkClick] = useState(false);

  const [forminputs, setforminputs] = useState(
    localStorage.getItem("forminputs")
      ? JSON.parse(localStorage.getItem("forminputs"))
      : {}
  );
  useEffect(() => {
    localStorage.setItem("forminputs", JSON.stringify(forminputs));
  }, [forminputs]);

  const [tableLoader, setTableLoader] = useState(false);
  const [tableData, setTableData] = useState({});

  async function getPageInsights() {
    const { jwttoken } = localStorage;

    if (
      (!forminputs?.PageAccount?.PageId &&
        !forminputs?.PageAccount?.InstaId &&
        !forminputs?.PageAccount?.fb_ad_id) ||
      !forminputs?.metricskeys
    ) {
      return;
    }

    let apiUrl;
    let id;
    if (forminputs.PageAccount.fb_pagesId) {
      apiUrl = "getfacebookinsights";
      id = forminputs.PageAccount.fb_pagesId;
    } else if (forminputs.PageAccount.InstaId) {
      apiUrl = "getinstagraminsights";
      id = forminputs.PageAccount.InstaId;
    } else if (forminputs.PageAccount.fb_ad_id) {
      apiUrl = "getfbadsinsights";
      id = forminputs.PageAccount.fb_ad_id;
    } else {
      apiUrl = "";
      id = "";
    }

    try {
      setTableLoader(true);
      const response = await axios.post(
        `${serverRoot + apiUrl}`,
        {
          id: id,
          metricskeys: forminputs.metricskeys,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: `Bearer ${jwttoken}`,
          },
        }
      );
      const { data } = response;
      if (data.isError) {
        return toast.error(data.message);
      }
      return setTableData(data.data);
    } catch (error) {
      return console.log(error);
    } finally {
      setTableLoader(false);
    }
  }

  return (
    <Routes>
      <Route element={<PrivateRoutes />}>
        {/* facebook leads Routes */}

        <Route path="/webhook/facebook/leads" element={<FacebookLeads />} />

        {/* ----------------------- */}

        <Route
          path="/flows"
          element={
            <Flows
              forminputs={forminputs}
              setforminputs={setforminputs}
              tableData={tableData}
              setTableData={setTableData}
              tableLoader={tableLoader}
              getPageInsights={getPageInsights}
            />
          }
        >
          <Route
            path="form"
            element={
              <Forms forminputs={forminputs} setforminputs={setforminputs} />
            }
          />
        </Route>
        <Route
          path="/flows/source"
          element={
            <Source
              forminputs={forminputs}
              setforminputs={setforminputs}
              setisLinkClick={setisLinkClick}
              getPageInsights={getPageInsights}
            />
          }
        />
        <Route
          path="/flows/source/facebook"
          element={
            <Facebook
              isLinkClick={isLinkClick}
              setisLinkClick={setisLinkClick}
              forminputs={forminputs}
            />
          }
        />

        <Route
          path="/flows/destination"
          element={
            <Destination
              forminputs={forminputs}
              setforminputs={setforminputs}
              setisLinkClick={setisLinkClick}
            />
          }
        />
        <Route
          path="/flows/destination/google"
          element={
            <Google isLinkClick={isLinkClick} setisLinkClick={setisLinkClick} />
          }
        />

        <Route path="/" element={<Dashboard />} />
      </Route>

      {/* looker studio routes*/}
      <Route path="/connector/redirect" element={<Redirect />} />
      <Route path="/connector/callback" element={<Callback />} />

      {/* *instagram login* */}
      <Route path="/instagram/insta-login" element={<InstaLogin />} />
      {/* ******************** */}

      <Route path="/login" element={<Login setIsLoggedIn={setIsLoggedIn} />} />
      <Route path="/signup" element={<Signup />} />
      <Route path="*" element={<ErrorPage />} />
    </Routes>
  );
};

function App() {
  const location = useLocation();
  const [isLoggedIn, setIsLoggedIn] = useState(
    localStorage.getItem("jwttoken")
      ? Boolean(localStorage.getItem("jwttoken"))
      : false
  );
  return (
    <>
      <NavBar isLoggedIn={isLoggedIn} setIsLoggedIn={setIsLoggedIn} />
      <Routing setIsLoggedIn={setIsLoggedIn} />
    </>
  );
}

export default App;
