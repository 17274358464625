import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { BrowserRouter } from 'react-router-dom';
import { ThemeProvider } from "@material-tailwind/react";
import { ToastContainer } from "react-toastify";
const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  // <React.StrictMode>
    <ThemeProvider>
      <BrowserRouter>
          <App />
          <ToastContainer autoClose={2000} position="bottom-right" theme="dark" />
      </BrowserRouter>
    </ThemeProvider>
  // </React.StrictMode>
);

