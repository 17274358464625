import React, {useState} from 'react';
import fbicon from "../../../images/facebook.png";
import instaicon from "../../../images/insta1.png";
import twittericon from "../../../images/twitter.png";
import sheeticon from "../../../images/sheets.png";

const Services = ({forminputs,setforminputs,onNext}) => {

    const myServices = [
        {   
            id: 1,
            title: "Google Sheet",
            value: "google_sheet",
            platform:"google",
            icon: sheeticon
        },
    ]

    const handleInputs = (e) =>{
        let selectedservice = myServices.find(u => u.id == e.target.value);
        setforminputs({...forminputs,destService: selectedservice});
        onNext();
    }


    return (
        <>
            <div className="w-full min-h-screen bg-gray-100 p-10">
                <div className="w-full h-10 flex justify-center items-center ">
                    <div className="relative">
                    <label className="relative block">
                        <span className="sr-only">Search</span>
                        <span className="absolute inset-y-0 left-0 flex items-center pl-2">
                            <i className="bi bi-search"></i>
                        </span>
                        <input className="placeholder:italic placeholder:text-slate-400 block bg-white w-full border border-slate-300 rounded-md py-2 pl-9 pr-12 focus:outline-none focus:border-black focus:ring-black focus:ring-1 sm:text-sm" placeholder="Search Destination" type="text" name="search"/>
                    </label>
                    </div>
                </div>
                <div className="capitalize mt-10 flex flex-wrap pl-5 gap-6">
                    {
                        myServices.map((elem, i)=>{
                            return(
                                <button key={i} onClick={() => handleInputs({target:{name:'media_desc',value:elem.id}})} className="h-24 w-24 bg-white hover:scale-110 hover:ring-[3px] ring-light-blue-600  rounded-[15px] drop-shadow-lg transition-all duration-300">
                                    <div className="w-full h-[4rem] flex justify-center items-center -mt-2">
                                        <img src={elem.icon} alt="icon" className="w-9"/>
                                    </div>
                                    <div className="text-sm font-medium text-center px-2 -mt-2 leading-4">
                                        <h2 className="capitalize">{elem.title}</h2>
                                    </div>
                                </button>
                            )
                        })
                    }
                    <button className="h-24 w-24 bg-white hover:scale-110 hover:ring-[3px] ring-light-blue-600 rounded-[15px] drop-shadow-lg transition-all duration-300">
                        
                    </button>
                    <button className="h-24 w-24 bg-white hover:scale-110 hover:ring-[3px] ring-light-blue-600 rounded-[15px] drop-shadow-lg transition-all duration-300">
                        
                    </button>
                    <button className="h-24 w-24 bg-white hover:scale-110 hover:ring-[3px] ring-light-blue-600 rounded-[15px] drop-shadow-lg transition-all duration-300">
                        
                    </button>
                    <button className="h-24 w-24 bg-white hover:scale-110 hover:ring-[3px] ring-light-blue-600 rounded-[15px] drop-shadow-lg transition-all duration-300">
                        
                    </button>
                    <button className="h-24 w-24 bg-white hover:scale-110 hover:ring-[3px] ring-light-blue-600 rounded-[15px] drop-shadow-lg transition-all duration-300">
                        
                    </button>
                    <button className="h-24 w-24 bg-white hover:scale-110 hover:ring-[3px] ring-light-blue-600 rounded-[15px] drop-shadow-lg transition-all duration-300">
                        
                    </button>
                    <button className="h-24 w-24 bg-white hover:scale-110 hover:ring-[3px] ring-light-blue-600 rounded-[15px] drop-shadow-lg transition-all duration-300">
                        
                    </button>
                    <button className="h-24 w-24 bg-white hover:scale-110 hover:ring-[3px] ring-light-blue-600 rounded-[15px] drop-shadow-lg transition-all duration-300">
                        
                    </button>
                    <button className="h-24 w-24 bg-white hover:scale-110 hover:ring-[3px] ring-light-blue-600 rounded-[15px] drop-shadow-lg transition-all duration-300">
                        
                    </button>
                </div>
            </div>
        </>
     );
}
 
export default Services;;