import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';

const PrivateRoutes = () => {
    const getIsLoggedIn = () =>{
        if(!localStorage.getItem("jwttoken")){
            return false;
        } else{
            return true;
        }
    }

    let auth = {'token':getIsLoggedIn()}
    return ( 
        auth.token ? <Outlet /> : <Navigate to="/login" replace/>    
    );
}

 
export default PrivateRoutes;