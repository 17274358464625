import React,{useState, useEffect} from 'react';
import {useNavigate, Outlet } from 'react-router-dom';
import Services from "./Services";
import Account from "./Account";

const Destination = ({forminputs, setforminputs, setisLinkClick}) => {


    const [destStep, setdestStep] = useState((localStorage.getItem("destStep")) ? 
    parseInt(localStorage.getItem("destStep")):1);
    const navigate = useNavigate();

    useEffect(()=>{
        localStorage.setItem("destStep",destStep);
    },[destStep]);

    const handleNext = () => {
        if(destStep === 2){
            navigate("/flows");
            return;
        }
        setdestStep(destStep + 1);
    };

    const handlePrevious = () => {
        if(destStep === 1){
            navigate("/flows");
            return;
        }
        setdestStep(destStep - 1);
    };


    return ( 
        <>
            <div className="w-full min-h-screen bg-gray-100">
                <div className="w-full bg-white h-28 drop-shadow-md">
                    <div className="h-2/3 px-10 text-black border-b flex justify-between items-center">
                        <div className="">
                            <button onClick={handlePrevious} className="inline-block rounded bg-[#E3EBF7] px-6 pt-2.5 pb-2 text-xs font-medium uppercase leading-normal transition duration-150 ease-in-out hover:bg-[#d2dff1]">
                                <i className="bi bi-chevron-left"></i>
                                Back
                            </button>
                        </div>
                        <div className="text-xl">
                            <h1 className="capitalize">select Destination</h1>
                        </div>
                        <div>
                            {
                                (destStep === 1 ) ? 
                                <button disabled  onClick={handleNext} className="inline-block rounded bg-gray-300 cursor-not-allowed text-gray-700 px-6 pt-2.5 pb-2 text-xs font-medium uppercase leading-normal transition duration-150 ease-in-out">
                                    Next
                                    <i className="bi bi-chevron-right"></i>
                                </button>
                                : (destStep === 2 && !forminputs.destAccount) ? 
                                <button disabled  onClick={handleNext} className="inline-block rounded bg-gray-300 cursor-not-allowed text-gray-700 px-6 pt-2.5 pb-2 text-xs font-medium uppercase leading-normal transition duration-150 ease-in-out">
                                    Next
                                    <i className="bi bi-chevron-right"></i>
                                </button> 
                                :<button onClick={handleNext} className="inline-block rounded bg-[#3B71CA] text-white px-6 pt-2.5 pb-2 text-xs font-medium uppercase leading-normal transition duration-150 ease-in-out hover:bg-[#2b5496]">
                                    {
                                        (destStep === 2) ? "Done":"Next"
                                    }
                                    <i className="bi bi-chevron-right"></i>
                                </button>
                            }
                        </div>
                    </div>

                </div>

                {destStep === 1 && <Services forminputs={forminputs} setforminputs={setforminputs} onNext={handleNext}/>}
                {destStep === 2 && <Account forminputs={forminputs} setforminputs={setforminputs} setisLinkClick={setisLinkClick}/>}

            </div>

            <Outlet />
        </>
     );
}
 
export default Destination;