import React, { useState, useEffect } from "react";
import { useNavigate, Outlet } from "react-router-dom";
import Services from "./Services/Services";
import Accounts from "./Accounts/Accounts";
import Metrics from "./Metrics/Metrics";
import TestPage from "./TestPage/TestPage";

const Source = ({
  forminputs,
  setforminputs,
  setisLinkClick,
  getPageInsights,
}) => {
  const [sourceStep, setsourceStep] = useState(
    localStorage.getItem("sourceStep")
      ? parseInt(localStorage.getItem("sourceStep"))
      : 1
  );
  const navigate = useNavigate();

  useEffect(() => {
    localStorage.setItem("sourceStep", sourceStep);
  }, [sourceStep]);

  const handleNext = () => {
    if (sourceStep === 4) {
      getPageInsights();
      navigate("/flows");
      return;
    }
    setsourceStep(sourceStep + 1);
  };

  const handlePrevious = () => {
    if (sourceStep === 1) {
      navigate("/flows");
      return;
    }
    setsourceStep(sourceStep - 1);
  };

  return (
    <>
      <div className="w-full min-h-screen bg-gray-100">
        <div className="w-full bg-white h-28 drop-shadow-md">
          <div className="h-2/3 px-10 text-black border-b flex justify-between items-center">
            <div className="">
              <button
                onClick={handlePrevious}
                type="button"
                className="inline-block rounded bg-[#E3EBF7] px-6 pt-2.5 pb-2 text-xs font-medium uppercase leading-normal transition duration-150 ease-in-out hover:bg-[#d2dff1]"
              >
                <i className="bi bi-chevron-left"></i>
                Back
              </button>
            </div>
            <div className="text-xl">
              <h1 className="capitalize">
                {forminputs.service
                  ? forminputs.service.title + " Insights"
                  : "select service"}
              </h1>
            </div>
            <div>
              {sourceStep === 1 ? (
                <button
                  disabled
                  onClick={handleNext}
                  className="inline-block rounded bg-gray-300 cursor-not-allowed text-gray-700 px-6 pt-2.5 pb-2 text-xs font-medium uppercase leading-normal transition duration-150 ease-in-out"
                >
                  Next
                  <i className="bi bi-chevron-right"></i>
                </button>
              ) : sourceStep === 2 &&
                (!forminputs.Account || !forminputs.PageAccount) ? (
                <button
                  disabled
                  onClick={handleNext}
                  className="inline-block rounded bg-gray-300 cursor-not-allowed text-gray-700 px-6 pt-2.5 pb-2 text-xs font-medium uppercase leading-normal transition duration-150 ease-in-out"
                >
                  Next
                  <i className="bi bi-chevron-right"></i>
                </button>
              ) : sourceStep === 3 && forminputs.metricskeys <= 0 ? (
                <button
                  disabled
                  onClick={handleNext}
                  className="inline-block rounded bg-gray-300 cursor-not-allowed text-gray-700 px-6 pt-2.5 pb-2 text-xs font-medium uppercase leading-normal transition duration-150 ease-in-out"
                >
                  Next
                  <i className="bi bi-chevron-right"></i>
                </button>
              ) : (
                <button
                  onClick={handleNext}
                  className="inline-block rounded bg-[#3B71CA] text-white px-6 pt-2.5 pb-2 text-xs font-medium uppercase leading-normal transition duration-150 ease-in-out hover:bg-[#2b5496]"
                >
                  {sourceStep === 4 ? "Done" : "Next"}
                  <i className="bi bi-chevron-right"></i>
                </button>
              )}
            </div>
          </div>
          <div className="flex justify-center items-center">
            <div className="flex items-center mt-2 justify-around w-1/2">
              <div className="flex text-sm font-medium tracking-wider">
                {sourceStep >= 2 ? (
                  <>
                    <i className="bi bi-check-circle-fill mr-2 text-green-500"></i>
                    <h1 className="text-green-500">Service</h1>
                  </>
                ) : sourceStep == 1 ? (
                  <h1 className="text-red-500">Service</h1>
                ) : (
                  <h1>Service</h1>
                )}
              </div>
              <i className="bi bi-chevron-right"></i>
              <div className="flex text-sm font-medium tracking-wider">
                {sourceStep >= 3 ? (
                  <>
                    <i className="bi bi-check-circle-fill mr-2 text-green-500"></i>
                    <h1 className="text-green-500">Account</h1>
                  </>
                ) : sourceStep == 2 ? (
                  <h1 className="text-red-500">Account</h1>
                ) : (
                  <h1>Account</h1>
                )}
              </div>
              <i className="bi bi-chevron-right"></i>
              <div className="flex text-sm font-medium tracking-wider">
                {sourceStep >= 4 ? (
                  <>
                    <i className="bi bi-check-circle-fill mr-2 text-green-500"></i>
                    <h1 className="text-green-500">Metrics</h1>
                  </>
                ) : sourceStep == 3 ? (
                  <h1 className="text-red-500">Metrics</h1>
                ) : (
                  <h1>Metrics</h1>
                )}
              </div>
              <i className="bi bi-chevron-right"></i>
              <div className="flex text-sm font-medium tracking-wider">
                {sourceStep >= 5 ? (
                  <>
                    <i className="bi bi-check-circle-fill mr-2 text-green-500"></i>
                    <h1 className="text-green-500">Test</h1>
                  </>
                ) : sourceStep == 4 ? (
                  <h1 className="text-red-500">Test</h1>
                ) : (
                  <h1>Test</h1>
                )}
              </div>
            </div>
          </div>
        </div>

        {sourceStep === 1 && (
          <Services setforminputs={setforminputs} onNext={handleNext} />
        )}
        {sourceStep === 2 && (
          <Accounts
            forminputs={forminputs}
            setforminputs={setforminputs}
            setisLinkClick={setisLinkClick}
          />
        )}
        {sourceStep === 3 && (
          <Metrics forminputs={forminputs} setforminputs={setforminputs} />
        )}
        {sourceStep === 4 && (
          <TestPage forminputs={forminputs} setforminputs={setforminputs} />
        )}
      </div>

      <Outlet />
    </>
  );
};

export default Source;
