import React, { useState, useEffect } from "react";
import toast, { Toaster } from "react-hot-toast";
import { Typography, Button } from "@material-tailwind/react";
import { useLocation, useNavigate } from "react-router-dom";
import { facebookUser, deleteSocialTokenfromDb } from "./API/facebook.js";
import { BsFacebook, BsInstagram } from "react-icons/bs";
import Loader from "./Loader.js";

const fbAuthURL = process.env.REACT_APP_FB_AUTH_LINK;
const linkedAuthURL = process.env.REACT_APP_LINKEDIN_AUTH_LINK;

const categories = [
  {
    name: "facebook-insights",
    categoryId: 1,
    title: "facebook insights",
    authURL: `${fbAuthURL}email, pages_show_list, read_insights, pages_read_engagement, pages_read_user_content, business_management`,
    // logo: BsFacebook,
    colorCode: "#1877F2",
    scriptId: process.env.REACT_APP_GOOGLE_SCRIPTID_FBPAGE,
  },
  {
    name: "facebook-ads",
    categoryId: 2,
    title: "facebook ads",
    // authURL: `${fbAuthURL}email, ads_management, ads_read, pages_manage_ads`,
    authURL: `${fbAuthURL}email, ads_read, pages_show_list, read_insights, pages_read_engagement, pages_read_user_content, business_management`,
    // logo: BsFacebook,
    colorCode: "#1877F2",
    scriptId: process.env.REACT_APP_GOOGLE_SCRIPTID_FBADS,
  },
  {
    name: "instagram-insights",
    categoryId: 3,
    title: "instagram insights",
    authURL: `${fbAuthURL}email, instagram_basic,instagram_manage_insights,pages_show_list,pages_read_engagement, pages_read_user_content, business_management, read_insights`,
    // logo: BsInstagram,
    colorCode: "#f74862",
    scriptId: process.env.REACT_APP_GOOGLE_SCRIPTID_INSTAGRAM,
  },
  {
    name: "linkedinCompanyPages",
    categoryId: 4,
    title: "LinkedIn Company Pages",
    authURL: `${linkedAuthURL}r_ads_reporting+r_liteprofile+r_organization_social+rw_organization_admin+w_member_social+r_ads+r_emailaddress+w_organization_social+rw_ads+r_basicprofile+r_organization_admin+r_1st_connections_size`,
    // logo: BsInstagram,
    colorCode: "#f74865",
    scriptId: process.env.REACT_APP_GOOGLE_SCRIPTID_LINKEDIN_COMPANY_PAGES,
  },
  {
    name: "linkedinAds",
    categoryId: 5,
    title: "LinkedIn Ads",
    authURL: `${linkedAuthURL}r_ads_reporting+r_liteprofile+r_organization_social+rw_organization_admin+w_member_social+r_ads+r_emailaddress+w_organization_social+rw_ads+r_basicprofile+r_organization_admin+r_1st_connections_size`,
    // logo: BsInstagram,
    colorCode: "#f74865",
    scriptId: process.env.REACT_APP_GOOGLE_SCRIPTID_LINKEDIN_ADS,
  },
];

const Callback = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const defaultValue = {
    name: "",
    categoryId: "",
    title: "",
    authURL: "",
    logo: "",
    colorCode: "#1877F2",
  };

  const [category, setCategory] = useState(
    sessionStorage.component
      ? JSON.parse(sessionStorage.component)
      : defaultValue
  );

  const [fbuser, setfbuser] = useState(
    sessionStorage.data ? JSON.parse(sessionStorage.data) : {}
  );

  const [loading, setLoading] = useState(false);

  const component = new URLSearchParams(location.search).get("component");
  const name = new URLSearchParams(location.search).get("name");
  const email = new URLSearchParams(location.search).get("email");
  const state = new URLSearchParams(location.search).get("state");

  useEffect(() => {
    if (name && email && state && component) {
      setLoading(true);
      const selectedCategory =
        categories.find((category) => category.name == component) || {};
      if (Object.keys(selectedCategory).length <= 0) {
        setLoading(false);
        return navigate("/");
      }
      sessionStorage.setItem("component", JSON.stringify(selectedCategory));
      setCategory(selectedCategory);
      sessionStorage.setItem("state", state);
      const categoryId = selectedCategory.categoryId;
      getUserCreds(name, email, categoryId);
      // navigate(`/connector/callback`);
    } else {
      // navigate("/");
      setLoading(false);
    }
    return;
  }, []);

  async function getUserCreds(name, email, categoryId) {
    const apiResponse = await facebookUser({
      name: name,
      email: email,
      categoryId: categoryId,
    });
    if (apiResponse.isError) {
      setLoading(false);
      return alert(apiResponse.message);
    }
    if (apiResponse.data.accounts.length <= 0) {
      addnewSocialAccounts();
    }
    sessionStorage.setItem("data", JSON.stringify(apiResponse.data));
    setfbuser(apiResponse.data);
    setLoading(false);
  }

  function handleSubmit() {
    const code = fbuser.user.token;
    const state = sessionStorage.state;
    if (state === "null" || !state || !code) {
      return;
    } else if (state == "JxyhONSjSUpPnK2r6RakIF6o9Ys") {
      window.close();
      if (window.closed === false) {
        return toast.error(
          "if the window does not close please close it manually."
        );
      }
      return;
    }

    window.location.href = `https://script.google.com/macros/d/${category.scriptId}/usercallback?code=${code}&state=${state}`;
    return sessionStorage.clear();
  }

  function addnewSocialAccounts() {
    return (window.location.href = category.authURL);
  }

  async function deleteSocialToken(TokenId) {
    try {
      const isDelete = window.confirm(
        "Are you sure! you want to delete the account."
      );
      if (isDelete) {
        const apiResp = await deleteSocialTokenfromDb(
          fbuser.user.token,
          TokenId
        );
        if (apiResp.isError == false) {
          toast.success("Successfully Removed!");
        }
      }
      getUserCreds(fbuser.user.Name, fbuser.user.UserName, category.categoryId);
    } catch (error) {
      return error;
    }
  }

  return (
    <div className="w-full h-[35rem] flex flex-col items-center bg-gray-100 transition-all ease-in duration-300 pt-5">
      {loading && <Loader />}
      <div className="bg-white p-6 rounded-lg shadow-lg min-w-[25rem]">
        <Typography
          variant="h3"
          className="mb-4 capitalize text-2xl font-semibold text-gray-800"
        >
          {category.title}
        </Typography>
        <label className="text-gray-500">You are currently logged in as:</label>
        <br />
        <Typography variant="small" className="font-semibold text-blue-700">
          {`${fbuser?.user?.Name} (${fbuser?.user?.UserName})`}
        </Typography>
        {fbuser.accounts && fbuser?.accounts.length > 0 ? (
          <>
            <h1 className="text-gray-700 font-semibold text-md mt-6">
              My Accounts
            </h1>
            <div className="flex flex-col gap-2 mt-1 border-b border-gray-300 pb-2">
              {fbuser.accounts.map((fb, i) => (
                <div
                  key={i}
                  className="flex justify-between items-center rounded-md px-3 py-2 hover:bg-gray-100 transition-colors duration-200"
                >
                  <span className="text-sm text-gray-800">{`${fb.name}(${fb.email})`}</span>
                  <div className="flex justify-center items-center gap-4">
                    <button onClick={addnewSocialAccounts}>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth="2"
                        stroke="currentColor"
                        className="w-[29px] h-[29px] hover:bg-blue-700/20 rounded-full p-1.5 text-blue-900"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M16.023 9.348h4.992v-.001M2.985 19.644v-4.992m0 0h4.992m-4.993 0l3.181 3.183a8.8 8.25 0 0013.803-3.7M4.031 9.865a8.25 8.25 0 0113.803-3.7l3.181 3.182m0-4.991v4.99"
                        />
                      </svg>
                    </button>
                    <button
                      onClick={() => deleteSocialToken(fb.TokenId)}
                      className="hover:bg-blue-700/20 rounded-full ease-in-out duration-150 text-blue-900"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="28"
                        height="28"
                        fill="currentColor"
                        className="bi bi-x"
                        viewBox="0 0 16 16"
                      >
                        <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
                      </svg>
                    </button>
                  </div>
                </div>
              ))}
            </div>
            <button
              onClick={handleSubmit}
              className="w-full mt-6 bg-gradient-to-r from-blue-500 to-blue-400 text-white py-2 rounded-md shadow-md hover:from-blue-600 hover:to-blue-500 transition-all duration-300 focus:outline-none"
            >
              Done
            </button>
          </>
        ) : null}
        <ButtonComponent
          handler={addnewSocialAccounts}
          title="Connect a new account"
          colorCode={category.colorCode}
          categoryId={category.categoryId}
        />
      </div>
      <Toaster
        position="top-right"
        reverseOrder={false}
        toastOptions={{ duration: 3000 }}
      />
    </div>
  );
};

const ButtonComponent = ({ handler, categoryId, title, colorCode }) => {
  return (
    <button
      onClick={handler}
      size="lg"
      variant="outlined"
      className={`focus:ring-0 border-none flex items-center justify-center gap-3 mt-10 w-full rounded px-5 py-2 overflow-hidden bg-black relative hover:bg-gradient-to-r hover:from-black hover:to-black text-white hover:ring-2 hover:ring-offset-2 hover:ring-black transition-all ease-out duration-300`}
    >
      {categoryId === 1 || categoryId === 2 ? (
        <BsFacebook className="w-6 h-6" />
      ) : categoryId === 3 ? (
        <BsInstagram className="w-6 h-6" />
      ) : (
        <div></div>
      )}
      {title}
    </button>
  );
};

export default Callback;
