import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

var facebookTokenLink = process.env.REACT_APP_FACEBOOK_TOKENLINK;
var instaTokenLink = process.env.REACT_APP_INSTAGRAM_TOKENLINK;
var facebookAdsTokenLink = process.env.REACT_APP_FACEBOOK_ADS_TOKENLINK;
const serverRoot = process.env.REACT_APP_SERVER_ROOT;

const Facebook = ({ isLinkClick, setisLinkClick, forminputs }) => {
  const [isLoading, setisLoading] = useState(false);
  var UserId = localStorage.getItem("UserId");
  const navigate = useNavigate();

  useEffect(() => {
    if (isLinkClick) {
      const tokenLink =
        forminputs.service.value == "InstaPageInsights"
          ? instaTokenLink
          : forminputs.service.value == "facebook_ads_insights"
          ? facebookAdsTokenLink
          : facebookTokenLink;

      window.location.href = tokenLink;
      setisLinkClick(false);
    }
  }, []);

  //facebook token//
  function getParameterByName(name, url = window.location.href) {
    name = name.replace(/[\[\]]/g, "\\$&");
    var regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
      results = regex.exec(url);
    if (!results) return null;
    if (!results[2]) return "";
    return decodeURIComponent(results[2].replace(/\+/g, " "));
  }

  // async function getfblongliveToken(code1) {

  //     await fetch(serverRoot+'getaccesstoken',
  //     {
  //         method: "POST",
  //         headers: {
  //             "Content-Type": "application/json",
  //             'Accept': 'application/json'
  //         },
  //         body: JSON.stringify({code1,UserId}),
  //     })
  //     .then((response) => {
  //         return response.json();
  //     })
  //     .catch((error) =>{
  //         return error;
  //     });
  // }

  async function getcodefromurl() {
    if (window.location.href.includes("code")) {
      let code1 = getParameterByName("code");
      // getfblongliveToken(code1);
      // navigate('/flows/source');
      setisLoading(true);
      await fetch(serverRoot + "getaccesstoken", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify({ code1, UserId }),
      })
        .then((response) => {
          return response.json();
        })
        .catch((error) => {
          return error;
        })
        .finally(() => {
          setisLoading(false);
          navigate("/flows/source");
          return;
        });
    }
  }

  useEffect(() => {
    getcodefromurl();
  }, []);
  //*****************//

  return (
    <>
      {isLoading ? (
        <div className="fixed inset-0 bg-black bg-opacity-30 backdop-blur-sm flex justify-center items-center">
          <lord-icon
            src="https://cdn.lordicon.com/xjovhxra.json"
            trigger="loop"
            scale="85"
            colors="primary:#109173,secondary:#08a88a"
            className="w-[50rem] h-[50rem]"
          ></lord-icon>
        </div>
      ) : (
        <div></div>
      )}
    </>
  );
};

export default Facebook;
