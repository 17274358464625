import React from 'react';
import { NavLink } from "react-router-dom";

const ErrorPage = () => {

    const buttonStyles = "inline-flex items-center px-4 py-2 ml-4 text-xs font-semibold tracking-widest text-white uppercase transition duration-150 ease-in-out bg-gray-900 border border-transparent rounded-md active:bg-gray-900 false"


    return ( 

        <>
            <div className="m-auto font-bold  text-center bg-white h-screen p-64">
                <h1 className='text-5xl'>WE ARE SORRY, PAGE NOT FOUND!</h1>
                <h4 className='text-gray-500 text-xs pt-2'>THE PAGE YOU ARE LOOKING FOR MIGHT HAVE BEEN REMOVED HAD ITS 
                    NAEM CHANGED OR IS TEMPORARILY <br />
                    UNAVAILABLE</h4>
                <div className="flex items-center justify-center mt-4">
                    <NavLink to="/flows" className={buttonStyles}>Back to Home Page</NavLink>
                </div>
            </div>
        </>
     );
}
 
export default ErrorPage;  