import React, {useState, useEffect} from 'react';
import {useNavigate} from 'react-router-dom';

const getgoogleaccesstokenlink = process.env.REACT_APP_GOOGLEACCESSTOKENLINK;
const serverRoot = process.env.REACT_APP_SERVER_ROOT;

const Google = ({isLinkClick, setisLinkClick}) => {

    let UserId = localStorage.getItem('UserId');

    const [isLoading, setisLoading] = useState(false);


    const navigate = useNavigate();
    useEffect(()=>{
        if(isLinkClick){
            window.location.href = getgoogleaccesstokenlink;
            setisLinkClick(false);
        }
    },[]);


    // storing google acces token to database //
    // const addgoogleTokenTodb = async(code) =>{
    //     await fetch(serverRoot+'googleToken',
    //     {
    //         method: "POST",
    //         headers: {
    //             "Content-Type": "application/json",
    //             'Accept': 'application/json'
    //         },
    //     body: JSON.stringify({code,UserId}),
    //     }) 
    //     .then((response) =>{
    //         return response.json();
    //     })
    //     .catch((error) =>{
    //         return error;
    //     })
    // }

    function getParameterByName(name, url = window.location.href) {
        name = name.replace(/[\[\]]/g, '\\$&');
        var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
            results = regex.exec(url);
        if (!results) return null;
        if (!results[2]) return '';
        return decodeURIComponent(results[2].replace(/\+/g, ' '));
    }
    
    async function getcodefromurl(){
        if(window.location.href.includes("code")){
            let code=getParameterByName("code");
            setisLoading(true);
            // return addgoogleTokenTodb(code1);
            await fetch(serverRoot+'googleToken',
            {
                method: "POST",
                headers: {"Content-Type": "application/json",'Accept': 'application/json'},
                body: JSON.stringify({code,UserId}),
            }) 
            .then((response) =>{
                if(response.status == 500){
                    window.alert("someone already using this id.");
                }
                return response.json();
            })
            .catch((error) =>{
                return error;
            })
            .finally(()=>{
                setisLoading(false);
                navigate('/flows/destination');
                return;
            });
        }
    }
    useEffect(()=>{
        getcodefromurl();
    },[]);

    // ................. //


    return(
        <>  
            {
                (isLoading)?
                <div className="fixed inset-0 bg-black bg-opacity-30 backdop-blur-sm flex justify-center items-center">
                    <lord-icon
                        src="https://cdn.lordicon.com/xjovhxra.json"
                        trigger="loop"
                        scale="85"
                        colors="primary:#109173,secondary:#08a88a"
                        className="w-[50rem] h-[50rem]">
                    </lord-icon>
                </div>
                :
                <div></div>

            }
        </>
    )


}
 
export default Google;