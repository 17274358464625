import React, { useState, useEffect } from "react";

import { NavLink } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import arrowicon from "../../images/arrowicon.png";
import SheetLists from "./SheetLists";
import illustration from "../../images/illustration3.png";
import { Card, Typography, Avatar } from "@material-tailwind/react";
const serverRoot = process.env.REACT_APP_SERVER_ROOT;

const Flows = ({
  forminputs,
  setforminputs,
  tableData,
  setTableData,
  tableLoader,
  getPageInsights,
}) => {
  const { UserId, jwttoken } = localStorage;

  const [sheets, setsheets] = useState([]);
  const [sheetLoader, setsheetLoader] = useState(false);
  const [isLoading, setisLoading] = useState(false);

  const createcronjobs = (e) => {
    e.preventDefault();
    setisLoading(true);
    fetch(serverRoot + "createcronjobs", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${jwttoken}`,
      },
      body: JSON.stringify({ forminputs }),
    })
      .then((response) => {
        if (response.ok) {
          setforminputs({});
          setTimeout(() => {
            getsheetsfromdb();
          }, 500);
          return toast
            .success("submited successfully.")
            .then(() => getsheetsfromdb());
        } else if (response.status === 405) {
          return toast.info("sheet already present");
        } else {
          return toast.error("something went wrong!");
        }
      })
      .catch((error) => {
        console.error(error);
        toast.error("An error occurred while submitting.");
      })
      .finally(() => setisLoading(false));
  };

  const getsheetsfromdb = () => {
    setsheetLoader(true);
    fetch(serverRoot + "getcronjobs/" + UserId, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${jwttoken}`,
      },
    })
      .then((response) => response.json())
      .then((responseData) => setsheets(responseData))
      .catch((error) => console.log(error))
      .finally(() => setsheetLoader(false));
  };

  useEffect(() => {
    getsheetsfromdb();
    localStorage.removeItem("sourceStep");
    localStorage.removeItem("destStep");
  }, []);

  const sourceIcon = forminputs.service ? (
    <img src={forminputs.service.icon} alt="icon" className="w-10" />
  ) : (
    <i className="bi bi-plus-lg"></i>
  );
  const destinationIcon = forminputs.destService ? (
    <img src={forminputs.destService.icon} alt="icon" className="w-10" />
  ) : (
    <i className="bi bi-plus-lg"></i>
  );
  return (
    <>
      <div className="min-h-screen w-full bg-gray-50 ">
        <div className="bg-white w-full h-28 flex justify-center items-center">
          <div className="flex flex-col justify-center items-center">
            <h1 className="font-normal text-sm uppercase text-blue-gray-400">
              Select Account
            </h1>
            <NavLink
              to="/flows/source"
              className="h-16 w-16 bg-white rounded-lg border hover:border-black hover:scale-110 transition-all duration-300 drop-shadow-lg flex justify-center items-center text-2xl font-bold"
            >
              {sourceIcon}
            </NavLink>
          </div>
        </div>

        {forminputs.service || forminputs.destService ? (
          <div className="flex mt-5 px-10 gap-5 ">
            <div>
              <Forms
                forminputs={forminputs}
                setforminputs={setforminputs}
                createcronjobs={createcronjobs}
                isLoading={isLoading}
                setTableData={setTableData}
              />
            </div>
            <div>
              {forminputs.PageAccount.InstaId ? (
                <InstaTable
                  tableData={tableData}
                  tableLoader={tableLoader}
                  getPageInsights={getPageInsights}
                />
              ) : forminputs.PageAccount.fb_ad_id ? (
                <FacebookAdsTable
                  tableData={tableData}
                  tableLoader={tableLoader}
                  getPageInsights={getPageInsights}
                />
              ) : (
                <FacebookTable
                  tableData={tableData}
                  tableLoader={tableLoader}
                  getPageInsights={getPageInsights}
                />
              )}
            </div>
          </div>
        ) : (
          <div className="flex flex-col justify-center items-center -mt-5 pb-2 mt-10">
            <div>
              <img src={illustration} alt="picture" className="w-[20rem]" />
            </div>
            <div>
              <h1 className="text-lg">Create Your first data flow</h1>
            </div>
            <div>
              <NavLink
                to="/flows/source"
                className="bg-blue-500 rounded-md text-white text-base px-3 py-2"
              >
                Create Source
              </NavLink>
            </div>
          </div>
        )}
      </div>
      <ToastContainer autoClose={2000} position="bottom-right" theme="dark" />
    </>
  );
};

const Forms = ({
  forminputs,
  setforminputs,
  createcronjobs,
  isLoading,
  getPageInsights,
  setTableData,
}) => {
  return (
    <>
      <div className="bg-[#173c6b] text-white w-[20rem] p-4 rounded-md drop-shadow-sm">
        {forminputs.service ? (
          <div className="w-auto flex items-center">
            <div className="p-[6px] bg-white rounded-full w-10">
              <img src={forminputs.service.icon} alt="icon" />
            </div>
            <h2 className="tracking-wide indent-4 capitalize">
              {forminputs.service.title} Insights
            </h2>
          </div>
        ) : (
          <></>
        )}

        <div className="-leading-4 mt-6 text-sm font-extralight tracking-wider">
          <div className="mb-4">
            <p>Facebook Account</p>
            <p>
              {forminputs.Account?.fbName +
                "(" +
                forminputs.Account?.fbId +
                ")" || "-"}
            </p>
          </div>
          <div className="mb-4 capitalize">
            <p>{forminputs.service?.title || "Facebook Page"}</p>
            <p>
              {forminputs.PageAccount
                ? `${
                    forminputs.PageAccount.PageName ||
                    forminputs.PageAccount.adaccount_name ||
                    forminputs.PageAccount.InstaPageUserName
                  }(${
                    forminputs.PageAccount.PageId ||
                    forminputs.PageAccount.adaccount_id ||
                    forminputs.PageAccount.InstaPageID
                  })`
                : "-"}
            </p>
          </div>
          {forminputs.metricsdisplayname &&
          forminputs.metricsdisplayname.length > 0 ? (
            <>
              <div className="w-full mb-4 max-h-52 py-2 shadow-inner overflow-hidden overflow-y-scroll overflow-x-hidden scrollbar-thin scrollbar-thumb-gray-700  scrollbar-thumb-rou scrollbar-track-blue-gray-50 scroll-smooth">
                <p>Metrics</p>
                <div className="flex flex-wrap w-full min-h-40 text-xs">
                  {forminputs.metricsdisplayname.map((metrics, i) => {
                    return (
                      <div
                        key={i}
                        className="bg-gray-200 m-1 h-min rounded-sm text-black px-[4px]"
                      >
                        {metrics}
                      </div>
                    );
                  })}
                </div>
              </div>
            </>
          ) : (
            <></>
          )}
        </div>

        <div className="flex justify-around px-5 items-center">
          <button
            onClick={() => {
              setforminputs({});
              setTableData({});
            }}
            className="border py-1 rounded-md px-2 tracking-wide text-sm uppercase transition-all duration-300"
          >
            Cancel
          </button>
          {forminputs.Account &&
          forminputs.destAccount &&
          forminputs.metricskeys ? (
            isLoading ? (
              <button
                disabled
                type="button"
                className="cursor-not-allowed py-1 px-2.5 mr-2 text-sm font-medium text-gray-900 bg-white rounded border border-gray-200 hover:bg-gray-100 hover:text-blue-700 inline-flex items-center"
              >
                <svg
                  aria-hidden="true"
                  role="status"
                  className="inline mr-2 w-4 h-4 text-gray-200 animate-spin dark:text-gray-600"
                  viewBox="0 0 100 101"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                    fill="currentColor"
                  ></path>
                  <path
                    d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                    fill="#1C64F2"
                  ></path>
                </svg>
                Loading...
              </button>
            ) : (
              <button
                onClick={createcronjobs}
                type="button"
                className="py-1 px-7 mr-2 text-sm font-medium text-gray-900 bg-white rounded border border-gray-200 hover:bg-gray-100 hover:text-blue-700 inline-flex items-center"
              >
                Create
              </button>
            )
          ) : (
            <></>
          )}
        </div>
      </div>
    </>
  );
};

const FacebookTable = ({ tableData, tableLoader, getPageInsights }) => {
  return (
    <>
      <button
        onClick={getPageInsights}
        className="text-sm bg-blue-500 px-2 py-1 text-white rounded-sm transition-all duration-300 hover:bg-blue-800"
      >
        Refresh
      </button>
      {tableLoader ? (
        <Card className="w-[60rem] h-[25rem] p-4">
          <div className="animate-pulse">
            <div className="h-10 bg-gray-200 mt-3 mb-6 rounded"></div>
            <div className="h-6 bg-gray-300 mb-6 rounded"></div>
            <div className="h-6 bg-gray-200 mb-6 rounded"></div>
            <div className="h-6 bg-gray-300 mb-6 rounded"></div>
            <div className="h-6 bg-gray-200 mb-6 rounded"></div>
            <div className="h-6 bg-gray-300 mb-6 rounded"></div>
            <div className="h-6 bg-gray-200 mb-6 rounded"></div>
            <div className="h-6 bg-gray-300 mb-6 rounded"></div>
            <div className="h-6 bg-gray-200 mb-6 rounded"></div>
          </div>
        </Card>
      ) : (
        <div>
          {tableData && Object.keys(tableData).length > 0 ? (
            <Card className="w-[56rem] h-[25rem] overflow-auto">
              <table className="w-min min-w-max table-auto text-left">
                <thead className="bg-gray-100 sticky top-0">
                  <tr>
                    {tableData?.sheetheaders.map((head) => (
                      <th
                        key={head}
                        className="border-b border-blue-gray-100 bg-blue-gray-50 p-4"
                      >
                        <Typography
                          variant="small"
                          color="blue-gray"
                          className="font-normal leading-none opacity-70"
                        >
                          {head}
                        </Typography>
                      </th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {tableData.sheetData.map((item, index) => (
                    <tr key={index}>
                      {tableData.sheetheaders.map((key) => (
                        <td
                          key={key}
                          className="p-2 border-b border-blue-gray-50 text-center"
                        >
                          <Typography
                            variant="small"
                            color="blue-gray"
                            className="font-normal"
                          >
                            {item[key] || 0}
                          </Typography>
                        </td>
                      ))}
                    </tr>
                  ))}
                </tbody>
              </table>
            </Card>
          ) : (
            <></>
          )}
        </div>
      )}
    </>
  );
};

const InstaTable = ({ tableData, tableLoader, getPageInsights }) => {
  return (
    <>
      <button
        onClick={getPageInsights}
        className="text-sm bg-blue-500 px-2 py-1 text-white rounded-sm transition-all duration-300 hover:bg-blue-800"
      >
        Refresh
      </button>
      {tableLoader ? (
        <Card className="w-[20rem] h-[25rem] p-4">
          <div className="animate-pulse">
            <div className="h-10 bg-gray-200 mt-3 mb-6 rounded"></div>
            <div className="h-6 bg-gray-300 mb-6 rounded"></div>
            <div className="h-6 bg-gray-200 mb-6 rounded"></div>
            <div className="h-6 bg-gray-300 mb-6 rounded"></div>
            <div className="h-6 bg-gray-200 mb-6 rounded"></div>
            <div className="h-6 bg-gray-300 mb-6 rounded"></div>
            <div className="h-6 bg-gray-200 mb-6 rounded"></div>
            <div className="h-6 bg-gray-300 mb-6 rounded"></div>
            <div className="h-6 bg-gray-200 mb-6 rounded"></div>
          </div>
        </Card>
      ) : (
        <div>
          {tableData.insights && Object.keys(tableData.insights).length > 0 ? (
            <Card className="w-auto h-auto overflow-auto overflow-x-hidden">
              <div className="my-4 px-4 py-6 flex justify-between  bg-white border border-gray-400 rounded-md">
                <div className="flex items-center gap-4">
                  <Avatar
                    src={tableData.profile_picture_url}
                    alt="avatar"
                    variant="rounded"
                  />
                  <div>
                    <Typography variant="h6">{tableData.name}</Typography>
                    <Typography
                      variant="small"
                      color="gray"
                      className="font-normal"
                    >
                      {tableData.username}
                    </Typography>
                  </div>
                </div>
                <div className="text-center text-black ">
                  <Typography>{tableData.followers_count}</Typography>
                  <Typography variant="h6" className="text-gray-600">
                    Followers
                  </Typography>
                </div>
                <div className="text-center text-black ">
                  <Typography>{tableData.follows_count}</Typography>
                  <Typography variant="h6" className="text-gray-600">
                    Following
                  </Typography>
                </div>
                <div className="text-center text-black ">
                  <Typography>{tableData.media_count}</Typography>
                  <Typography variant="h6" className="text-gray-600">
                    Posts
                  </Typography>
                </div>
              </div>

              <section class="text-black body-font mt-4">
                <Typography variant="h4">Recent Posts</Typography>
                <div class="container  py-5 mx-auto">
                  <div class="flex flex-wrap -m-4">
                    {tableData.media && tableData.media.length > 0 ? (
                      tableData.media.map((m, i) => {
                        return (
                          <div class="lg:w-1/4 md:w-1/2 p-4 w-full" key={m.id}>
                            <a
                              href={m.permalink}
                              target="_blank"
                              rel="noreferrer"
                              class="block relative h-60 rounded overflow-hidden"
                            >
                              <img
                                alt="ecommerce"
                                class="object-cover object-center w-full h-full block"
                                src={m?.thumbnail_url || m?.media_url}
                              />
                            </a>
                            <div class="text-center mt-2 leading-none flex justify-center  bottom-0 left-0 w-full py-4">
                              <span class="text-black mr-3 inline-flex items-center leading-none text-sm pr-3 py-1 border-r-2 border-gray-200">
                                <svg
                                  class="w-4 h-4 mr-1"
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="16"
                                  height="16"
                                  fill="currentColor"
                                  viewBox="0 0 16 16"
                                >
                                  <path d="M8.864.046C7.908-.193 7.02.53 6.956 1.466c-.072 1.051-.23 2.016-.428 2.59-.125.36-.479 1.013-1.04 1.639-.557.623-1.282 1.178-2.131 1.41C2.685 7.288 2 7.87 2 8.72v4.001c0 .845.682 1.464 1.448 1.545 1.07.114 1.564.415 2.068.723l.048.03c.272.165.578.348.97.484.397.136.861.217 1.466.217h3.5c.937 0 1.599-.477 1.934-1.064a1.86 1.86 0 0 0 .254-.912c0-.152-.023-.312-.077-.464.201-.263.38-.578.488-.901.11-.33.172-.762.004-1.149.069-.13.12-.269.159-.403.077-.27.113-.568.113-.857 0-.288-.036-.585-.113-.856a2 2 0 0 0-.138-.362 1.9 1.9 0 0 0 .234-1.734c-.206-.592-.682-1.1-1.2-1.272-.847-.282-1.803-.276-2.516-.211a10 10 0 0 0-.443.05 9.4 9.4 0 0 0-.062-4.509A1.38 1.38 0 0 0 9.125.111zM11.5 14.721H8c-.51 0-.863-.069-1.14-.164-.281-.097-.506-.228-.776-.393l-.04-.024c-.555-.339-1.198-.731-2.49-.868-.333-.036-.554-.29-.554-.55V8.72c0-.254.226-.543.62-.65 1.095-.3 1.977-.996 2.614-1.708.635-.71 1.064-1.475 1.238-1.978.243-.7.407-1.768.482-2.85.025-.362.36-.594.667-.518l.262.066c.16.04.258.143.288.255a8.34 8.34 0 0 1-.145 4.725.5.5 0 0 0 .595.644l.003-.001.014-.003.058-.014a9 9 0 0 1 1.036-.157c.663-.06 1.457-.054 2.11.164.175.058.45.3.57.65.107.308.087.67-.266 1.022l-.353.353.353.354c.043.043.105.141.154.315.048.167.075.37.075.581 0 .212-.027.414-.075.582-.05.174-.111.272-.154.315l-.353.353.353.354c.047.047.109.177.005.488a2.2 2.2 0 0 1-.505.805l-.353.353.353.354c.006.005.041.05.041.17a.9.9 0 0 1-.121.416c-.165.288-.503.56-1.066.56z" />
                                </svg>
                                {m.like_count}
                              </span>
                              <span class="text-black inline-flex items-center leading-none text-sm">
                                <svg
                                  class="w-4 h-4 mr-1"
                                  stroke="currentColor"
                                  stroke-width="2"
                                  fill="none"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                  viewBox="0 0 24 24"
                                >
                                  <path d="M21 11.5a8.38 8.38 0 01-.9 3.8 8.5 8.5 0 01-7.6 4.7 8.38 8.38 0 01-3.8-.9L3 21l1.9-5.7a8.38 8.38 0 01-.9-3.8 8.5 8.5 0 014.7-7.6 8.38 8.38 0 013.8-.9h.5a8.48 8.48 0 018 8v.5z"></path>
                                </svg>
                                {m.comments_count}
                              </span>
                            </div>
                          </div>
                        );
                      })
                    ) : (
                      <></>
                    )}
                  </div>
                </div>
              </section>
              <Typography variant="h4" className="my-4">
                Insights
              </Typography>
              <table className="w-min min-w-max table-auto text-left">
                <thead className="bg-gray-100 sticky top-0">
                  <tr>
                    <th className="border-b border-blue-gray-100 bg-blue-gray-50 p-4 ">
                      <Typography
                        variant="small"
                        color="blue-gray"
                        className="font-normal leading-none opacity-70 px-20"
                      >
                        Metrics
                      </Typography>
                    </th>
                    <th className="border-b border-blue-gray-100 bg-blue-gray-50 p-4">
                      <Typography
                        variant="small"
                        color="blue-gray"
                        className="font-normal leading-none opacity-70 px-20"
                      >
                        Values
                      </Typography>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {Object.entries(tableData.insights).map(([key, value]) => {
                    return (
                      <tr key={key}>
                        <td className="p-2 border-b border-blue-gray-50 text-center">
                          <Typography
                            variant="small"
                            color="blue-gray"
                            className="font-normal"
                          >
                            {key}
                          </Typography>
                        </td>
                        <td className="p-2 border-b border-blue-gray-50 text-center">
                          <Typography
                            variant="small"
                            color="blue-gray"
                            className="font-normal"
                          >
                            {value}
                          </Typography>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </Card>
          ) : (
            <></>
          )}
        </div>
      )}
    </>
  );
};

const FacebookAdsTable = ({ tableData, tableLoader, getPageInsights }) => {
  return (
    <>
      <button
        onClick={getPageInsights}
        className="text-sm bg-blue-500 px-2 py-1 text-white rounded-sm transition-all duration-300 hover:bg-blue-800"
      >
        Refresh
      </button>
      {tableLoader ? (
        <Card className="w-[38rem] h-[25rem] p-4">
          <div className="animate-pulse">
            <div className="h-10 bg-gray-200 mt-3 mb-6 rounded"></div>
            <div className="h-6 bg-gray-300 mb-6 rounded"></div>
            <div className="h-6 bg-gray-200 mb-6 rounded"></div>
            <div className="h-6 bg-gray-300 mb-6 rounded"></div>
            <div className="h-6 bg-gray-200 mb-6 rounded"></div>
            <div className="h-6 bg-gray-300 mb-6 rounded"></div>
            <div className="h-6 bg-gray-200 mb-6 rounded"></div>
            <div className="h-6 bg-gray-300 mb-6 rounded"></div>
            <div className="h-6 bg-gray-200 mb-6 rounded"></div>
          </div>
        </Card>
      ) : (
        <div>
          {tableData && Object.keys(tableData).length > 0 ? (
            <Card className="w-auto h-[30rem] overflow-auto overflow-x-hidden mb-10">
              <table className="w-min min-w-max table-auto text-left">
                <thead className="bg-gray-100 sticky top-0">
                  <tr>
                    <th className="border-b border-blue-gray-100 bg-blue-gray-50 p-4 ">
                      <Typography
                        variant="small"
                        color="blue-gray"
                        className="font-normal leading-none opacity-70 px-32"
                      >
                        Metrics
                      </Typography>
                    </th>
                    <th className="border-b border-blue-gray-100 bg-blue-gray-50 p-4">
                      <Typography
                        variant="small"
                        color="blue-gray"
                        className="font-normal leading-none opacity-70 px-20"
                      >
                        Values
                      </Typography>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {Object.entries(tableData).map(([key, value]) => {
                    return (
                      <tr key={key}>
                        <td className="p-2 border-b border-blue-gray-50 text-center">
                          <Typography
                            variant="small"
                            color="blue-gray"
                            className="font-normal"
                          >
                            {key}
                          </Typography>
                        </td>
                        <td className="p-2 border-b border-blue-gray-50 text-center">
                          <Typography
                            variant="small"
                            color="blue-gray"
                            className="font-normal"
                          >
                            {value}
                          </Typography>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </Card>
          ) : (
            <></>
          )}
        </div>
      )}
    </>
  );
};

export default Flows;
