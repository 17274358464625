import axios from "axios";

// const serverRoot = import.meta.env.VITE_SERVER_ROOT;
const serverRoot = process.env.REACT_APP_LOOKER_STUDIO_SERVER_ROOT;
const headers = {
  "Content-Type": "application/json",
  Accept: "application/json",
};

export async function validateSocialToken(token, formData) {
  try {
    const response = await axios.post(
      `${serverRoot}validate-social-token`,
      formData,
      {
        headers: { ...headers, Authorization: "Bearer " + token },
      }
    );
    return response.data;
  } catch (error) {
    return handleErrorResponse(error);
  }
}
export async function deleteSocialTokenfromDb(token, TokenId) {
  try {
    const response = await axios.delete(
      `${serverRoot}delete-social-token/${TokenId}`,
      {
        headers: { ...headers, Authorization: "Bearer " + token },
      }
    );
    return response.data;
  } catch (error) {
    return handleErrorResponse(error);
  }
}

export async function facebookUser(formData) {
  try {
    const response = await axios.post(`${serverRoot}facebook-user`, formData, {
      headers: { ...headers },
    });
    return response.data;
  } catch (error) {
    return handleErrorResponse(error);
  }
}

export async function facebookPage(token, formData) {
  try {
    const response = await axios.post(`${serverRoot}facebook-page`, formData, {
      headers: { ...headers, Authorization: "Bearer " + token },
    });
    return { data: response.data };
  } catch (error) {
    return handleErrorResponse(error);
  }
}

export async function facebookAdsPage(token, formData) {
  try {
    const response = await axios.post(`${serverRoot}facebook-ads`, formData, {
      headers: { ...headers, Authorization: "Bearer " + token },
    });
    return { data: response.data };
  } catch (error) {
    return handleErrorResponse(error);
  }
}

export async function instagramPage(token, formData) {
  try {
    const response = await axios.post(`${serverRoot}instagram-page`, formData, {
      headers: { ...headers, Authorization: "Bearer " + token },
    });
    return { data: response.data };
  } catch (error) {
    return handleErrorResponse(error);
  }
}

export function handleErrorResponse(error) {
  if (error.response && error.response.data) {
    return { data: error.response.data };
  }
  return { data: error.message };
}
