import React from "react";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const Flowcard = ({ forminputs, setforminputs, createcronjobs }) => {
  // const [mediatype, setmediatype] = useState({});

  // const findmediatypes = () =>{
  //     switch (forminputs.service.value) {
  //         case 'facebook_page_insights':
  //             setmediatype({formidname:"fb_pagesId", fetchURL:"fbuserpagedatafromdb/" });
  //             break;
  //         case 'facebook_post_insights':
  //             setmediatype({formidname:"fb_pagesId", fetchURL:"fbuserpagedatafromdb/" });
  //             break;
  //         case 'facebook_ads_insights':
  //             setmediatype({formidname:"fb_ad_id", fetchURL:"fbuseradsdatafromdb/" });
  //             break;
  //         case 'InstaPageInsights':
  //             setmediatype({formidname:"InstaId", fetchURL:"userinstaaccounts/" });
  //             break;
  //         case 'InstaPostInsights':
  //             setmediatype({formidname:"InstaId", fetchURL:"userinstaaccounts/" });
  //             break;
  //         default:
  //             break;
  //     }
  // }
  // useEffect(()=>{
  //     findmediatypes();
  // },[]);

  return (
    <>
      <div className="relative bg-[#173c6b] text-white w-[20rem] p-4 rounded-md drop-shadow-sm">
        {forminputs.service ? (
          <div className="w-auto flex items-center">
            <div className="p-[6px] bg-white rounded-full w-10">
              <img src={forminputs.service.icon} alt="icon" />
            </div>
            <h2 className="tracking-wide indent-4 capitalize">
              {forminputs.service.title} Insights
            </h2>
          </div>
        ) : (
          <></>
        )}

        <div className="-leading-4 mt-6 text-sm font-extralight tracking-wider">
          <div className="mb-4">
            <p>Facebook Account</p>
            <p>
              {forminputs.Account
                ? forminputs.Account.fbName +
                  "(" +
                  forminputs.Account.fbId +
                  ")"
                : "-"}
            </p>
          </div>
          <div className="mb-4 capitalize">
            <p>
              {forminputs.service ? forminputs.service.title : "Facebook Page"}
            </p>
            {/* <p>{(forminputs.PageAccount)? forminputs.PageAccount.PageName ||forminputs.PageAccount.adaccount_name ||forminputs.PageAccount.InstaPageUserName + "(" + (forminputs.PageAccount.PageId || forminputs.PageAccount.adaccount_id || forminputs.PageAccount.InstaPageID) + ")" : "-"}</p> */}
            <p>
              {forminputs.PageAccount
                ? `${
                    forminputs.PageAccount.PageName ||
                    forminputs.PageAccount.adaccount_name ||
                    forminputs.PageAccount.InstaPageUserName
                  }(${
                    forminputs.PageAccount.PageId ||
                    forminputs.PageAccount.adaccount_id ||
                    forminputs.PageAccount.InstaPageID
                  })`
                : "-"}
            </p>
          </div>
          {forminputs.metricsdisplayname &&
          forminputs.metricsdisplayname.length > 0 ? (
            <>
              <div className="w-full mb-4 max-h-52 py-2 shadow-inner overflow-hidden overflow-y-scroll overflow-x-hidden scrollbar-thin scrollbar-thumb-[#1d2e46]  scrollbar-thumb-rounded-full scrollbar-track-rounded-full scroll-smooth">
                <p>Metrics</p>
                <div className="flex flex-wrap w-full min-h-40 text-xs">
                  {forminputs.metricsdisplayname.map((metrics, i) => {
                    return (
                      <div
                        key={i}
                        className="bg-gray-200 m-1 h-min rounded-sm text-black px-[4px]"
                      >
                        {metrics}
                      </div>
                    );
                  })}
                </div>
              </div>
            </>
          ) : (
            <></>
          )}
        </div>
      </div>
      <ToastContainer autoClose={2000} position="bottom-right" theme="dark" />
    </>
  );
};

export default Flowcard;
