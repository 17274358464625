import React, { useState, useEffect } from "react";
import { useNavigate, NavLink } from "react-router-dom";

import Flowcard from "../TestPage/Flowcard";

const serverRoot = process.env.REACT_APP_SERVER_ROOT;

const Accounts = ({ forminputs, setforminputs, setisLinkClick }) => {
  let UserId = localStorage.getItem("UserId");
  const navigate = useNavigate();

  const [userfbaccounts, setuserfbaccounts] = useState([]);
  const [userfbpageaccounts, setuserfbpageaccounts] = useState([]);
  const [isPagesLoading, setisPagesLoading] = useState(false);
  const [isAccountLoading, setisAccountLoading] = useState(false);

  const [mediatype, setmediatype] = useState({});

  const findmediatypes = async () => {
    let mediatypes = {};
    switch (forminputs.service ? forminputs.service.value : "") {
      case "facebook_page_insights":
      case "facebook_post_insights":
      case "facebook_leads":
        mediatypes = {
          formidname: "fb_pagesId",
          fetchURL: "fbuserpagedatafromdb/",
          id: "PageId",
        };
        break;
      case "facebook_ads_insights":
        mediatypes = {
          formidname: "fb_ad_id",
          fetchURL: "fbuseradsdatafromdb/",
          id: "adaccount_id",
        };
        break;
      case "InstaPageInsights":
      case "InstaPostInsights":
        mediatypes = {
          formidname: "InstaId",
          fetchURL: "userinstaaccounts/",
          id: "InstaPageID",
        };
        break;
      case "linkedinPages":
        mediatypes = {
          formidname: "id",
          fetchURL: "userLinkedinPages/",
          id: "linkedinPageId",
        };
        break;
      default:
        break;
    }
    setmediatype(mediatypes);
    return mediatypes;
  };

  const fbuserdatafromdb = () => {
    setisAccountLoading(true);
    fetch(serverRoot + "fbuserdatafromdb/" + UserId, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    })
      .then((response) => response.json())
      .then((responseData) => {
        setuserfbaccounts(responseData);
        // if (!forminputs.Account && responseData.length > 0) {
        //   setforminputs({ ...forminputs, Account: responseData[0] });
        // }
        if (responseData.length > 0) {
          setforminputs({ ...forminputs, Account: responseData[0] });
        }
      })
      .catch((error) => console.log(error))
      .finally(() => {
        setisAccountLoading(false);
      });
  };
  useEffect(() => {
    findmediatypes().then(() => {
      fbuserdatafromdb();
    });
  }, []);

  function fbuserpagedatafromdb(TokenId, fetchURL) {
    setisPagesLoading(true);
    fetch(serverRoot + fetchURL + TokenId, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    })
      .then((response) => response.json())
      .then((responseData) => {
        setuserfbpageaccounts(responseData);
        if (responseData.length > 0) {
          setforminputs({ ...forminputs, PageAccount: responseData[0] });
        }
      })
      .catch((error) => console.log(error))
      .finally(() => setisPagesLoading(false));
  }

  useEffect(() => {
    findmediatypes()
      .then((resp) => {
        if (!forminputs.Account) {
          return;
        } else {
          let TokenId = forminputs.Account.TokenId;
          fbuserpagedatafromdb(TokenId, resp.fetchURL);
        }
      })
      .catch((error) => console.log(error));
  }, [forminputs.Account]);

  const handleAccountInputs = (e) => {
    let { value } = e.target;
    let Account = userfbaccounts.find((u) => u.TokenId == value);
    setforminputs({ ...forminputs, Account: Account });
    // fbuserpagedatafromdb(value);
  };

  const handlePageInputs = (e) => {
    let { value } = e.target;
    let PageAccount = userfbpageaccounts.find(
      (u) => u[mediatype.formidname] == value
    );
    setforminputs({ ...forminputs, PageAccount: PageAccount });
  };

  const getToken = () => {
    setisLinkClick(true);
    navigate("/flows/source/facebook");
  };

  return (
    <>
      <div className="w-full min-h-screen bg-gray-100">
        <div className="flex gap-5 p-10  text-white">
          <Flowcard forminputs={forminputs} />

          <div className="bg-white text-black w-full h-auto px-5 py-8 drop-shadow-sm rounded-md">
            <div>
              <h1 className="font-[550] ">Account</h1>
            </div>
            <div>
              <p className="text-gray-400 text-sm">
                Select the Facebook Account and page you want to retrieve data
                from, or add a new account . <br /> you can only select one page
                at a time.
              </p>
            </div>
            <div className="mt-4">
              <button
                onClick={getToken}
                className="bg-light-blue-500 font-medium text-white text-sm  px-2 py-1 rounded-md tracking-wide"
              >
                create new account
              </button>
            </div>
            <div className="capitalize mt-4 -leading-4 text-sm font-medium tracking-wider">
              <div className="mb-4">
                <h2 className="">Facebook Account</h2>
                {isAccountLoading ? (
                  <div className="flex bg-gray-200 w-[18rem] py-1.5 px-2">
                    <div
                      className="inline-block h-6 w-6 animate-spin rounded-full border-4 border-solid border-current border-r-transparent align-[-0.125em] text-gray-500 motion-reduce:animate-[spin_1.5s_linear_infinite]"
                      role="status"
                    >
                      <span className="!absolute !-m-px !h-px !w-px !overflow-hidden !whitespace-nowrap !border-0 !p-0 ![clip:rect(0,0,0,0)]">
                        Loading...
                      </span>
                    </div>
                    <h1 className="text-sm ml-2 font-medium leading-normal">
                      Loading...
                    </h1>
                  </div>
                ) : userfbaccounts && userfbaccounts.length > 0 ? (
                  <select
                    onChange={handleAccountInputs}
                    defaultValue={
                      forminputs.Account
                        ? forminputs.Account.TokenId
                        : "Select Account"
                    }
                    className="focus:outline-none bg-[#F9FAFB] border pl-2 h-9 w-[21.5rem] rounded-[4px] tracking-wider"
                  >
                    <option value="Select Account" disabled>
                      Select Account
                    </option>
                    {userfbaccounts.map((e, i) => {
                      return (
                        <option key={i} value={e.TokenId}>
                          {e.fbName + "(" + e.fbId + ")"}
                        </option>
                      );
                    })}
                  </select>
                ) : (
                  <div className="flex bg-gray-200 w-[18rem] py-1.5 px-2">
                    No Accounts Found
                  </div>
                )}
              </div>
              <div className="mb-4">
                <h2 className="">
                  {forminputs.service
                    ? forminputs.service.title + " Account"
                    : "Facebook Page"}
                </h2>
                {isPagesLoading ? (
                  <div className="flex bg-gray-200 w-[18rem] py-1.5 px-2">
                    <div
                      className="inline-block h-6 w-6 animate-spin rounded-full border-4 border-solid border-current border-r-transparent align-[-0.125em] text-gray-500 motion-reduce:animate-[spin_1.5s_linear_infinite]"
                      role="status"
                    >
                      <span className="!absolute !-m-px !h-px !w-px !overflow-hidden !whitespace-nowrap !border-0 !p-0 ![clip:rect(0,0,0,0)]">
                        Loading...
                      </span>
                    </div>
                    <h1 className="text-sm ml-2 font-medium leading-normal">
                      Loading...
                    </h1>
                  </div>
                ) : userfbpageaccounts && userfbpageaccounts.length > 0 ? (
                  <select
                    onChange={handlePageInputs}
                    defaultValue={
                      forminputs.PageAccount
                        ? forminputs.PageAccount[mediatype.formidname]
                        : "Select Page"
                    }
                    className="focus:outline-none bg-[#F9FAFB] border pl-2 h-9 w-[21.5rem] rounded-[4px] tracking-wider"
                  >
                    <option value="Select Page" disabled>
                      Select Page
                    </option>
                    {userfbpageaccounts.map((e, i) => {
                      return (
                        <option
                          key={i}
                          value={e.fb_pagesId || e.fb_ad_id || e.InstaId}
                        >{`${
                          e.PageName || e.adaccount_name || e.InstaPageUserName
                        }(${e[mediatype.id]})`}</option>
                      );
                    })}
                  </select>
                ) : (
                  <div className="flex bg-gray-200 w-[18rem] py-1.5 px-2">
                    No Pages Found
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Accounts;
